import React, { Component } from "react";
// import logo from "./logo.svg";
import "./App.css";
import "./styles/main.less";


// import Header from "./components/structure/Header";
// import Footer from "./components/structure/Footer";
import $ from "jquery";
import Risikorechner from "Risikorechner";
import GeneralHelperClass from "helper/GeneralHelper";
import SectionsHelperClass from "helper/sectionsHelper";
import LeisureSectionsHelperClass from "helper/leisureSectionsHelper";
import ConfigHelperClass from "helper/ConfigHelper";
import LanguageHelperClass from "helper/LanguageHelper";
import RelationChartHelperClass from "helper/RelationChartHelper";
import ReactLoading from "react-loading";
import { Chart } from "react-chartjs-2";
import ReactDOMServer from "react-dom/server";
import Header from "components/Header/Header";
import { slide as Menu } from "react-burger-menu";
import { isMobile } from "react-device-detect";
// React Component
import Burger from "@animated-burgers/burger-squeeze";
// don't forget the styles
import "@animated-burgers/burger-squeeze/dist/styles.css";
import SmoothScroll from "components/SmoothScroll/SmoothScroll";
import * as Scroll from "react-scroll";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import EntityHelper from "helper/EntityHelper";
import RiskHelper from "helper/RiskHelper";
import RisikorechnerNeu from "RisikorechnerNeu";
import VergleichsHelper from "helper/VerlgeichsHelper";



class App extends Component {
  generalHelperClass = null;
  sectionsHelperClass = null;
  leisureSectionsHelperClass = null;
  configHelperClass = null;
  languageHelperClass = null;
  relationChartHelperClass = null;
  entityHelper = null;
  vergleichsHelper = null;

  // Combines all helper classes
  riskHelper = null;

  constructor(props) {
    super(props);

    Chart.defaults.global.defaultFontFamily =
      '"Roboto", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif';

    // Chart.pluginService.register({
    //   afterDraw: function (chart) {
    //     if (typeof chart.config.options.lineAt != "undefined") {
    //       var lineAtVar = chart.config.options.lineAt;
    //       var ctxPlugin = chart.chart.ctx; // canvas (CanvasRenderingContext2D)
    //       var xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
    //       var yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];

    //       // I'm not good at maths
    //       // So I couldn't find a way to make it work ...
    //       // ... without having the `min` property set to 0
    //       if (yAxe.min != 0) return;

    //       // ctxPlugin.font = "30px Comic Sans MS";
    //       // ctxPlugin.fillStyle = "red";
    //       // ctxPlugin.textAlign = "center";
    //       // ctxPlugin.fillText("Hello World", chart.canvas.width/2, yAxe.height + 10);

    //       // ctxPlugin.strokeStyle = "gray";
    //       // ctxPlugin.beginPath();
    //       // var lineAt = (lineAtVar - yAxe.min) * (100 / yAxe.max);
    //       // lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.top;
    //       // ctxPlugin.moveTo(xAxe.left, lineAt);
    //       // ctxPlugin.lineTo(xAxe.right, lineAt);
    //       // ctxPlugin.stroke();

    //       // ctxPlugin.beginPath();
    //       // lineAt = (lineAtVar - yAxe.min) * (100 / yAxe.max);
    //       // lineAt = ((100 - lineAt) / 100) * yAxe.height + yAxe.top + 5;
    //       // ctxPlugin.moveTo(xAxe.left, lineAt);
    //       // ctxPlugin.lineTo(xAxe.right, lineAt);
    //       // ctxPlugin.stroke();
    //     }
    //   },
    // });

    Chart.plugins.register({
      afterDatasetsDraw: function (chartInstance, easing) {
        if (typeof chartInstance.config.options.lineAtIndex != "undefined") {
          var lineAtVarIndex = chartInstance.config.options.lineAtIndex;
          var xAxe =
            chartInstance.scales[
              chartInstance.config.options.scales.xAxes[0].id
            ];
          var yAxe =
            chartInstance.scales[
              chartInstance.config.options.scales.yAxes[0].id
            ];
          // To only draw at the end of animation, check for easing === 1
          var ctx = chartInstance.chart.ctx;
          chartInstance.data.datasets.forEach(function (dataset, i) {
            if (lineAtVarIndex == i) {
              var meta = chartInstance.getDatasetMeta(i);
              if (!meta.hidden) {
                meta.data.forEach(function (element, index) {
                  // Draw the text in black, with the specified font

                  var fontSize = 16;
                  var fontStyle = "normal";
                  var fontFamily = "Helvetica Neue";
                  ctx.font = Chart.helpers.fontString(
                    fontSize,
                    fontStyle,
                    fontFamily
                  );

                  var dataString = "||";

                  var position = element.tooltipPosition();
                  ctx.fillStyle = "rgb(255, 255, 255)";
                  ctx.fillRect(position.x - 35, yAxe.height + 5, 15, 20);
                  ctx.fillStyle = "rgb(0, 0, 0)";
                  ctx.fillText(dataString, position.x - 27, yAxe.height + 9);
                });
              }
            }
          });
        }
      },
    });

    this.state = {
      error: null,
      loadingCount: 7, // count of files to load
      riskCalcJson: [],
      riskDetailsJson: [],
      configJson: [],
      languageJson: [],
      relationsJson: [],
      entities: [],
      taetigkeiten: [],
      geschlecht: [],
      altersgruppen: [],
      sparten: [],
      vergleich: [],
      
    };
  }

  async UNSAFE_componentWillMount() {
    // ====================================================== //
    // ================ LOAD risiko_calc.json =============== //
    // ====================================================== //
    fetch(process.env.REACT_APP_BACKEND_URL + "admin/json/risiko_calc.json")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            riskCalcJson: result,
          });

          this.setState({ loadingCount: this.state.loadingCount - 1 });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("err");
          this.setState({
            error: error,
          });
        }
      );

    // ====================================================== //
    // =============== LOAD risk_details.json =============== //
    // ====================================================== //
    fetch(process.env.REACT_APP_BACKEND_URL + "admin/json/risk_details.json")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            riskDetailsJson: result,
          });

          this.setState({ loadingCount: this.state.loadingCount - 1 });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("err");
          this.setState({
            error: error,
          });
        }
      );

    // ====================================================== //
    // ================== LOAD config.json ================== //
    // ====================================================== //
    fetch(process.env.REACT_APP_BACKEND_URL + "admin/json/config.json")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            configJson: result,
          });

          this.setState({ loadingCount: this.state.loadingCount - 1 });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log("err");
          this.setState({
            error: error,
          });
        }
      );

    // ====================================================== //
    // ================= LOAD language.json ================= //
    // ====================================================== //
    fetch(process.env.REACT_APP_BACKEND_URL + "admin/json/language.json")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            languageJson: result,
          });

          this.setState({ loadingCount: this.state.loadingCount - 1 });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // console.log("err");
          this.setState({
            error: error,
          });
        }
      );

    // ====================================================== //
    // ================= LOAD relations.json ================ //
    // ====================================================== //
    fetch(process.env.REACT_APP_BACKEND_URL + "admin/json/relations.json")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            relationsJson: result,
          });

          setTimeout(() => {
            // 300ms to show loading indicator
            this.setState({ loadingCount: this.state.loadingCount - 1 });
          }, 300);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          // console.log("err");
          this.setState({
            error: error,
          });
        }
      );

    // ====================================================== //
    // ================= LOAD GET ENTITÄTEN-DROPDOWN.json ================ //
    // ====================================================== //
        //https://jasonwatmore.com/post/2021/09/06/fetch-http-get-request-examples

    fetch("https://risiko.kfv.at/api/entitaeten")
    .then((res) => res.json())
    .then(
      (result) => {

        this.setState({
          entities: result,
          taetigkeiten: result.taetigkeiten,
          geschlecht: result.geschlecht,
          altersgruppen: result.altersgruppen,
          sparten: result.sparten,
        });

        setTimeout(() => {
          // 300ms to show loading indicator
          this.setState({ loadingCount: this.state.loadingCount - 1 });
        }, 300);
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        // console.log("err");
        this.setState({
          error: error,
        });
      }
    );

      // ====================================================== //
    // ================= LOAD GET ENTITÄTEN-DROPDOWN.json ================ //
    // ====================================================== //
        //https://jasonwatmore.com/post/2021/09/06/fetch-http-get-request-examples

        fetch("https://risiko.kfv.at/api/vergleich")
        .then((res) => res.json())
        .then(
          (result) => {
            console.log('Vergleich:')
            console.log(result);
            this.setState({
              vergleich: result,
            });
    
            setTimeout(() => {
              // 300ms to show loading indicator
              this.setState({ loadingCount: this.state.loadingCount - 1 });
            }, 300);
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            // console.log("err");
            this.setState({
              error: error,
            });
          }
        );

      
  }

  toggleAccordion(idNameString) {
    let idName = "#" + idNameString;
    if(!$(`${idName} button`).hasClass("active")) {
      $(`${idName} button`).click();
    }
  }



  



  render() {
    if (this.state.error) {
      return <div>Error: {this.state.error.message}</div>;
    } else if (this.state.loadingCount === 0) {
      this.configHelperClass = new ConfigHelperClass(this.state.configJson);
      this.languageHelperClass = new LanguageHelperClass(
        this.state.languageJson
      );

      this.sectionsHelperClass = new SectionsHelperClass(
        this.state.riskCalcJson,
        this.state.configJson,
        this.languageHelperClass
      );
      this.leisureSectionsHelperClass = new LeisureSectionsHelperClass(
        this.state.riskCalcJson,
        this.state.configJson,
        this.languageHelperClass
      );
      this.generalHelperClass = new GeneralHelperClass(
        this.state.riskDetailsJson,
        this.state.configJson,
        this.sectionsHelperClass,
        this.leisureSectionsHelperClass,
        this.languageHelperClass
      );

      this.relationChartHelperClass = new RelationChartHelperClass(
        this.state.relationsJson,
        this.state.configJson,
        this.languageHelperClass
      );

      this.entityHelper = new EntityHelper(
        this.state.entities
      )

      this.vergleichsHelper = new VergleichsHelper(
        this.state.vergleich
      )

      this.riskHelper = new RiskHelper(
        this.generalHelperClass,
        this.sectionsHelperClass,
        this.leisureSectionsHelperClass,
        this.configHelperClass,
        this.languageHelperClass,
        this.relationChartHelperClass,
        this.entityHelper
      );

      var isMenuOpen = function (state) {
        $(".bm-burger-button .burger-squeeze").toggleClass("open");
        return state.isOpen;
      };
      return (
        <React.Fragment>
          <Header
            className={isMobile ? "" : "sticky"}
            generalHelperClass={this.generalHelperClass}
            menuItem={
              <Menu
                // right
                width={ '280px' }
                onStateChange={isMenuOpen}
                customCrossIcon={false}
                customBurgerIcon={<Burger />}
              >
                <Link to="risk-hero" spy={true} smooth={true} offset={-100}>
                  <span
                    className="menu-item"
                    // onClick={() => {
                    //   SmoothScroll.scrollTo("main-content-anchor");
                    // }}
                  >
                    Risikorechner
                  </span>
                </Link>
                <Link
                  to="risk-calculation"
                  spy={true}
                  smooth={true}
                  offset={-150}
                >
                  <span
                    className="menu-item"
                    // onClick={() => {
                    //   SmoothScroll.scrollTo("main-content-anchor");
                    // }}
                  >
                    Berechnung
                  </span>
                </Link>
                {/* <Link
                  to="risk-calculation-traffic"
                  spy={true}
                  smooth={true}
                  offset={-160}
                  className={"sub-menu-item"}
                >
                  <span
                    className="menu-item"
                    onClick={() => {
                      this.toggleAccordion("risk-calculation-traffic-id")
                    }}
                  >
                    Straßenverkehr
                  </span>
                </Link> */}
                {/* <Link
                  to="risk-calculation-leisure"
                  spy={true}
                  smooth={true}
                  offset={-160}
                  className={"sub-menu-item"}
                >
                  <span
                    className="menu-item"
                    onClick={() => {
                      this.toggleAccordion("risk-calculation-leisure-id")
                    }}
                  >
                    Freizeit
                  </span>
                </Link> */}
                <Link to="risk-relation" spy={true} smooth={true} offset={-150}>
                  <span
                    className="menu-item"
                    // onClick={() => {
                    //   SmoothScroll.scrollTo("main-content-anchor");
                    // }}
                  >
                    Vergleichsgrafik
                  </span>
                </Link>
                {/* <Link
                  to="risk-relation-traffic"
                  spy={true}
                  smooth={true}
                  offset={-160}
                  className={"sub-menu-item"}
                >
                  <span
                    className="menu-item"
                    onClick={() => {
                      this.toggleAccordion("risk-relation-traffic")
                    }}
                  >
                    Straßenverkehr
                  </span>
                </Link> */}
                {/* <Link
                  to="risk-relation-leisure"
                  spy={true}
                  smooth={true}
                  offset={-160}
                  className={"sub-menu-item"}
                >
                  <span
                    className="menu-item"
                    onClick={() => {
                      this.toggleAccordion("risk-relation-leisure-id")
                    }}
                  >
                    Freizeit
                  </span>
                </Link> */}
                <Link to="risk-details" spy={true} smooth={true} offset={-100}>
                  <span
                    className="menu-item"
                    // onClick={() => {
                    //   SmoothScroll.scrollTo("risk-details");
                    // }}
                  >
                    Verletzungen
                  </span>
                </Link>
              </Menu>
            }
          ></Header>

          {/* <Risikorechner
            riskCalcJson={this.state.riskCalcJson}
            riskDetailsJson={this.state.riskDetailsJson}
            sectionsHelperClass={this.sectionsHelperClass}
            leisureSectionsHelperClass={this.leisureSectionsHelperClass}
            generalHelperClass={this.generalHelperClass}
            configHelperClass={this.configHelperClass}
            relationChartHelperClass={this.relationChartHelperClass}
            languageHelperClass={this.languageHelperClass}
            entityHelper={this.entityHelper}
            riskHelper={this.riskHelper}
          ></Risikorechner> */}

          <RisikorechnerNeu
            riskCalcJson={this.state.riskCalcJson}
            riskDetailsJson={this.state.riskDetailsJson}
            sectionsHelperClass={this.sectionsHelperClass}
            leisureSectionsHelperClass={this.leisureSectionsHelperClass}
            generalHelperClass={this.generalHelperClass}
            configHelperClass={this.configHelperClass}
            relationChartHelperClass={this.relationChartHelperClass}
            languageHelperClass={this.languageHelperClass}
            entityHelper={this.entityHelper}
            vergleichsHelper={this.vergleichsHelper}
            riskHelper={this.riskHelper}
          ></RisikorechnerNeu>
        </React.Fragment>
      );
    } else {
      return (
        <ReactLoading
          type="bars"
          color="#0e51a7"
          className="loading-indicator"
        ></ReactLoading>
      );
    }
  }

  sidebarSetup() {}

  init() {}

  initialize() {}
}

export default App;
