import React, { useState, useEffect, useRef, createRef } from "react";
import PropTypes from "prop-types";
// import sectionsJson from "assets/json/risiko_calc";
import Accordion from "components/Collapsible/Accordion";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import Rangeslider from "components/Rangeslider/Rangeslider";
import RiskDetails from "components/RiskDetails/RiskDetails";
import Sidebar from "components/Sidebar/Sidebar";
import Widget from "components/Widget/Widget";
import RelationChartJS from "components/RelationChartJS/RelationChartJS";
import { Bar } from "react-chartjs-2";
import "styles/main.less";

import GeneralHelperClass from "helper/GeneralHelper";

import SmoothScroll from "components/SmoothScroll/SmoothScroll";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaInfoCircle } from "react-icons/all";
import ReactHtmlParser from "react-html-parser";
import Button from "components/Button/Button";
import parse from "html-react-parser";
import HTMLTippy from "components/HTMLTippy/HTMLTippy";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import EntityHelper from "helper/EntityHelper";
import Dropdown from "components/Dropdown/Dropdown";

var barOptions = {
  tooltips: {
    enabled: true,
    mode: "point", // mode: "x",
    callbacks: {
      label: GeneralHelperClass.removeNumberInTooltip,
    },
  },
  legend: {
    display: false,
    position: "right",
    align: "start",
    labels: {
      padding: 12,
      boxWidth: 10,
      fontSize: 11,
      filter: (legendItem, chartData) => {
        return (
          chartData.datasets[legendItem.datasetIndex].stack != "Durchschnitt"
        );
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: { display: false },
        stacked: true,
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: { display: false },
        stacked: true,
      },
    ],
  },
};

var barOptionsLeisure = {
  tooltips: {
    enabled: true,
    mode: "point", // mode: "x",
    callbacks: {
      label: GeneralHelperClass.removeNumberInTooltip,
    },
  },
  legend: {
    display: false,
    position: "bottom",
    align: "start",
    labels: {
      padding: 12,
      boxWidth: 10,
      fontSize: 11,
      filter: (legendItem, chartData) => {
        return (
          chartData.datasets[legendItem.datasetIndex].stack != "Durchschnitt"
        );
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: { display: false },
        stacked: true,
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: { display: false },
        stacked: true,
      },
    ],
  },
};

const Risikorechner = (props) => {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("resize", handleWindowSizeChange);
  });

  function handleWindowSizeChange() {
    // setScreenWidth(window.innerWidth);
    var newIsMobile = window.innerWidth <= 500 ? true : false;
    if (isMobile != newIsMobile) {
      setIsMobile(newIsMobile);
    }
  }

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= 500 ? true : false
  );

  function resetTrafficButtonPressed() {
    trafficRefs.current.forEach((element) => {
      if (element.current != null) {
        element.current.resetSlider();
      }
    });

    setDataTrafficBar(
      props.sectionsHelperClass.initTrafficState(selectedAge, selectedSex)
    );
  }

  function resetLeisureButtonPressed() {
    leisureRefs.current.forEach((element) => {
      if (element.current != null) {
        element.current.resetSlider();
      }
    });

    setDataLeisureBar(
      props.leisureSectionsHelperClass.initLeisureState(
        selectedAge,
        selectedSex
      )
    );
  }

  const trafficRefs = useRef(
    props.sectionsHelperClass.trafficObject().content.map(() => createRef())
  );
  const leisureRefs = useRef(
    props.leisureSectionsHelperClass
      .leisureObject()
      .content.map(() => createRef())
  );

  // ##################################################################### //
  // ######################### TRAFFIC PROPERTIES ######################## //
  // ##################################################################### //
  // User selects a slider in traffic -> reload data
  const [selectedTrafficState, setSelectedTrafficState] = useState({}); // {id: 0, value: 0.022}
  useEffect(() => {
    if (selectedTrafficState.id != undefined) {
      var newDataTrafficBar = props.sectionsHelperClass.calculateTrafficState(
        selectedSex,
        selectedAge,
        selectedTrafficState,
        dataTrafficBar
      );

      setDataTrafficBar(newDataTrafficBar);
    }
  }, [selectedTrafficState]);

  const [trafficSum, setTrafficSum] = useState(0.0);
  useEffect(() => {
    // selected state updated
    // console.log(`Changed traffic: ${trafficSum} `);
  }, [trafficSum]);

  // The current traffic data array
  const [dataTrafficBar, setDataTrafficBar] = useState(
    props.sectionsHelperClass.trafficDataset
  );
  useEffect(() => {
    if (
      dataTrafficBar != undefined &&
      dataTrafficBar.datasets != undefined &&
      dataTrafficBar.datasets.length > 0
    ) {
      let sum = props.sectionsHelperClass.trafficSumFromDataset(dataTrafficBar);
      setTrafficSum(sum);
    }
  }, [dataTrafficBar]);

  // ##################################################################### //
  // ######################### LEISURE PROPERTIES ######################## //
  // ##################################################################### //
  const [selectedLeisureState, setSelectedLeisureState] = useState({}); // {id: 0, value: 0.022}
  useEffect(() => {
    if (selectedLeisureState.id != undefined) {
      var newDataLeisureBar = props.leisureSectionsHelperClass.calculateLeisureState(
        selectedSex,
        selectedAge,
        selectedLeisureState,
        dataLeisureBar
      );

      setDataLeisureBar(newDataLeisureBar);
    }
  }, [selectedLeisureState]);

  const [leisureSum, setLeisureSum] = useState(0.0);
  useEffect(() => {
    // selected state updated
  }, [leisureSum]);

  // The current leisure data array
  const [dataLeisureBar, setDataLeisureBar] = useState(
    props.leisureSectionsHelperClass.leisureDataset
  );
  useEffect(() => {
    if (
      dataLeisureBar != undefined &&
      dataLeisureBar.datasets != undefined &&
      dataLeisureBar.datasets.length > 0
    ) {
      let sum = props.leisureSectionsHelperClass.leisureSumFromDataset(
        dataLeisureBar
      );
      setLeisureSum(sum);
    }
  }, [dataLeisureBar]);

  // ##################################################################### //
  // ######################### GENERAL PROPERTIES ######################## //
  // ##################################################################### //

  const [selectedAge, setSelectedAge] = useState(1);
  useEffect(() => {
    console.log(`selectedAge: ${selectedAge} `);


    // if (selectedLeisureState.id != undefined) {
      var newDataLeisureBar = props.leisureSectionsHelperClass.calculateLeisureState(
        selectedSex,
        selectedAge,
        selectedLeisureState,
        dataLeisureBar
      );

      setDataLeisureBar(newDataLeisureBar);
    // }

    // if (selectedTrafficState.id != undefined) {
      var newDataTrafficBar = props.sectionsHelperClass.calculateTrafficState(
        selectedSex,
        selectedAge,
        selectedTrafficState,
        dataTrafficBar
      );

      setDataTrafficBar(newDataTrafficBar);
    // }
  }, [selectedAge]);

  const [selectedSex, setSelectedSex] = useState(0);
  useEffect(() => {
    console.log(`selectedSex: ${selectedSex} `);
    // setDataTrafficBar(
    //   props.sectionsHelperClass.initTrafficState(selectedAge, selectedSex)
    // );

    // setDataLeisureBar(
    //   props.leisureSectionsHelperClass.initLeisureState(
    //     selectedAge,
    //     selectedSex
    //   )
    // );

    if (selectedLeisureState.id != undefined) {
      var newDataLeisureBar = props.leisureSectionsHelperClass.calculateLeisureState(
        selectedSex,
        selectedAge,
        selectedLeisureState,
        dataLeisureBar
      );

      setDataLeisureBar(newDataLeisureBar);
    }

    if (selectedTrafficState.id != undefined) {
      var newDataTrafficBar = props.sectionsHelperClass.calculateTrafficState(
        selectedSex,
        selectedAge,
        selectedTrafficState,
        dataTrafficBar
      );

      setDataTrafficBar(newDataTrafficBar);
    }
  }, [selectedSex]);

  


  const [showRiskDetails, setShowRiskDetails] = useState(true);
  const [showRiskCalculateContent, setShowRiskCalculateContent] = useState(
    true
  );

  const [selectedDropDownValues, setSelectedDropDownValues] = useState([]);
  useEffect(() => {
    console.log(`selectedDropDownValue: ${selectedDropDownValues} `);
    console.log(`selectedDropDownValueJSON: ${JSON.stringify(selectedDropDownValues)} `);
    console.log(`selectedDropDownValueLength: ${selectedDropDownValues.length} `);

  }, [selectedDropDownValues]);

  return (
    
    <React.Fragment>
      <div className="fullwidth widget main-content">
        <Widget
          riskHelper={props.riskHelper}
          onAgeSelected={(value) => setSelectedAge(value)}
          onSexSelected={(value) => setSelectedSex(value)}
          onCalculatePressed={() => {
            resetLeisureButtonPressed();
            resetTrafficButtonPressed();
            setDataTrafficBar(
              props.sectionsHelperClass.initTrafficState(
                selectedAge,
                selectedSex
              )
            );

            setDataLeisureBar(
              props.leisureSectionsHelperClass.initLeisureState(
                selectedAge,
                selectedSex
              )
            );

            setShowRiskCalculateContent(true);
            // setTimeout(function () {
            //   SmoothScroll.scrollTo("main-content-anchor");
            // }, 300);
            setTimeout(function () {
              // scroller.scrollTo({
              //   to: "risk-calculation",
              // });
              scroller.scrollTo("risk-calculation", {
                smooth: true,
                offset: -150,
              });
            }, 300);
          }}
        />
      </div>

      <main>
        {RiskCalculateContent()}
        {RiskDetailsContent()}
      </main>

      <Footer />
    </React.Fragment>
  );

  function RelationChart(type) {
    return (
      <RelationChartJS
        relationChartHelperClass={props.relationChartHelperClass}
        generalHelperClass={props.generalHelperClass}
        type={type}
      ></RelationChartJS>
    );
  }

  function RiskCalculateContent() {

    if (showRiskCalculateContent) {
      return (
        <React.Fragment>
          <div className="fullwidth">
            <h1>Wie hoch ist Ihr Risiko?</h1>
          </div>
          <div className="wrapper risk-bars-main">
            <div id="main-content-anchor" className="main">
              <Element name="risk-calculation" className="element">
                <h2>Berechnung</h2>

                <Element name="risk-calculation-general" className="element">
                {calculationDropDown()}
                </Element>

                <Element name="risk-calculation-traffic" className="element">
                  <Accordion
                  id={"risk-calculation-traffic-id"}
                    title={
                      props.sectionsHelperClass.riskCalcJsonArray[0].sectionName
                    }
                  >
                    <div className="row accordion-row">
                      {/* ------- Column 1 -------  */}
                      <div className="double-column mr-4">
                        <div className="accordion-slider">
                          <p>
                            {
                              props.sectionsHelperClass.riskCalcJsonArray[0]
                                .text
                            }
                            <Tippy
                              content={parse(
                                props.languageHelperClass.infoTraffic()
                              )}
                              interactive={true}
                              placement={"bottom"}
                              zIndex={9999}
                            >
                              <span className="range-info">
                                <FaInfoCircle />
                              </span>
                            </Tippy>
                          </p>
                          <p>
                            <Button
                              label="Zurücksetzen"
                              handleClick={resetTrafficButtonPressed}
                            ></Button>
                          </p>
                          {resetTraffic()}
                        </div>
                      </div>
                      {/* ------- Column 2 -------  */}
                      <div className="column horizontal-chart traffi-bar-column">
                        <div className="percentage-traffic">
                          <HTMLTippy
                            content={props.languageHelperClass.trafficPercentageHover()}
                            showUnderline={false}
                          >
                            {getPercentageText(
                              props.generalHelperClass.calculateTrafficPercentageToAverageAustrian(
                                yourTrafficSum()
                              )
                            )}
                          </HTMLTippy>
                        </div>
                        <div className="row traffic-bar-row">
                          <Bar
                            type="bar"
                            data={dataTrafficBar}
                            height={260}
                            options={barOptions}
                          ></Bar>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </Element>
                <Element name="risk-calculation-leisure" className="element">
                <Accordion
                id={"risk-calculation-leisure-id"}
                  title={
                    props.sectionsHelperClass.riskCalcJsonArray[1].sectionName
                  }
                >
                  <div className="row accordion-row">
                    {/* ------- Column 1 -------  */}
                    <div className="double-column mr-4">
                      <div className="accordion-slider">
                        <p>
                          {props.sectionsHelperClass.riskCalcJsonArray[1].text}
                          <Tippy
                            content={parse(
                              props.languageHelperClass.infoLeisure()
                            )}
                            interactive={true}
                            placement={"bottom"}
                            zIndex={9999}
                          >
                            <span className="range-info">
                              <FaInfoCircle />
                            </span>
                          </Tippy>
                        </p>
                        <p>
                          <Button
                            label="Zurücksetzen"
                            handleClick={resetLeisureButtonPressed}
                          ></Button>
                        </p>
                        {resetLeisure()}
                      </div>
                    </div>
                    {/* ------- Column 2 -------  */}
                    <div className="column horizontal-chart">
                      <div className="percentage-leisure">
                        <HTMLTippy
                          content={props.languageHelperClass.leisurePercentageHover()}
                          showUnderline={false}
                        >
                          {getPercentageText(
                            props.generalHelperClass.calculateLeisurePercentageToAverageAustrian(
                              yourLeisureSum()
                            )
                          )}
                        </HTMLTippy>
                      </div>
                      <div className="row leisure-bar-row">
                        <Bar
                          type="bar"
                          data={dataLeisureBar}
                          height={650}
                          options={barOptionsLeisure}
                        ></Bar>
                      </div>
                    </div>
                  </div>
                </Accordion>
                </Element>
             
              </Element>
              <Element name="risk-relation" className="element">
                <h2>Vergleichsgrafik</h2>
                <Element name="risk-relation-traffic" className="element">
                <Accordion
                  title={props.configHelperClass.relationTrafficTitle()}
                  id={"risk-relation-traffic"}
                >
                  <p>
                    {ReactHtmlParser(
                      props.configHelperClass.relationChartInfoTraffic()
                    )}
                  </p>
                  <div className="relation-chart-wrapper">
                    {RelationChart(0)}
                  </div>
                </Accordion>
                </Element>
                <Element name="risk-relation-leisure" className="element">
                <Accordion
                  id={"risk-relation-leisure-id"}
                  title={props.configHelperClass.relationLeisureTitle()}
                >
                  <p>
                    {ReactHtmlParser(
                      props.configHelperClass.relationChartInfoLeisure()
                    )}
                  </p>
                  <div className="relation-chart-wrapper">
                    {RelationChart(1)}
                  </div>
                </Accordion>
                </Element>
              </Element>
            </div>

            <Sidebar
              sectionsHelperClass={props.sectionsHelperClass}
              leisureSectionsHelperClass={props.leisureSectionsHelperClass}
              generalHelperClass={props.generalHelperClass}
              configHelperClass={props.configHelperClass}
              languageHelperClass={props.languageHelperClass}
              trafficSumProp={yourTrafficSum()}
              leisureSumProp={yourLeisureSum()}
              averageSumProp={Number(
                props.sectionsHelperClass
                  .meinRestRiskValue(selectedAge, selectedSex)
                  .toFixed(4)
              )}
              onShowDetailsPressed={() => {
                setShowRiskDetails(true);
                setTimeout(function () {
                  SmoothScroll.scrollTo("risk-details-anchor");
                }, 300);
              }}
              selectedAge={selectedAge}
              selectedSex={selectedSex}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  function yourTrafficSum() {
    return (
      Number(trafficSum.toFixed(4)) +
      Number(
        props.sectionsHelperClass.trafficRestRiskValue(selectedAge, selectedSex)
      )
    );
  }
  function yourLeisureSum() {
    return Number(leisureSum.toFixed(4));
  }

  function getPercentageText(percentageDecimal) {
    var percentage = Number(percentageDecimal) * 100;
    return (
      (percentageDecimal > 0 ? "+ " : "- ") +
      Math.abs(percentage).toFixed(0) +
      "%"
    );
  }

  function calculationDropDown() {
    let rangeSliders = <React.Fragment></React.Fragment>;
    console.log("Sel:");
    console.log(selectedDropDownValues);
    if(selectedDropDownValues.length > 0) {
      rangeSliders = selectedDropDownValues.map(
        (content, index) => {
          return (
            <Rangeslider
              sectionsHelperClass={props.sectionsHelperClass}
              ref={trafficRefs.current[index]}
              key={index}
              id={content.Taetigkeit}
              lineColor={content.color}
              label={content.Taetigkeit}
              hoverContent={content.hover}
              initSelected={Number(content.Regler_Presetting)}
              section={0}
              taetigkeitId={content.id}
              onRelease={(value) =>
                setSelectedTrafficState({
                  id: index,
                  value: value,
                })
              }
            />
          );
        }
      );
    } 

    return (
      <React.Fragment>
        <Dropdown
          // list={props.riskHelper.configHelperClass.ageOptions()}
          list={props.riskHelper.entityHelper.taetigkeitenDropDown()}
          onItemClick={(clickedItem, value) => {
            console.log("Clicked: " + clickedItem);
            console.log(
              "Clicked Value: " +
                JSON.stringify(
                  props.riskHelper.entityHelper.taetigkeitForId(clickedItem)
                )
            );

            if (!selectedDropDownValues.some((val) => val.id == clickedItem)) {
              // add taetigkeit to dropdown vals
              setSelectedDropDownValues((selectedDropDownValues) => [
                ...selectedDropDownValues,
                props.riskHelper.entityHelper.taetigkeitForId(clickedItem),
              ]);
            }
          }}
        />
        {rangeSliders}
      </React.Fragment>
    );
  }

  function resetTraffic() {
    // console.log(props.sectionsHelperClass.riskCalcJsonArray[0].content);
    return props.sectionsHelperClass.riskCalcJsonArray[0].content.map(
      (content, index) => {
        return (
          <Rangeslider
            sectionsHelperClass={props.sectionsHelperClass}
            ref={trafficRefs.current[index]}
            key={index}
            id={content.Taetigkeit}
            lineColor={content.color}
            label={content.Taetigkeit}
            hoverContent={content.hover}
            initSelected={Number(content.Regler_Presetting)}
            section={0}
            taetigkeitId={content.id}
            onRelease={(value) =>
              setSelectedTrafficState({
                id: index,
                value: value,
              })
            }
          />
        );
      }
    );
  }

  function resetLeisure() {
    return props.sectionsHelperClass.riskCalcJsonArray[1].content.map(
      (content, index) => {
        return (
          <Rangeslider
            sectionsHelperClass={props.sectionsHelperClass}
            ref={leisureRefs.current[index]}
            key={index}
            id={content.Taetigkeit}
            lineColor={content.color}
            hoverContent={content.hover}
            label={content.Taetigkeit}
            initSelected={Number(content.Regler_Presetting)}
            section={1}
            taetigkeitId={content.id}
            onRelease={(value) =>
              setSelectedLeisureState({
                id: index,
                value: value,
              })
            }
          />
        );
      }
    );
  }

  function RiskDetailsContent() {
    if (showRiskDetails) {
      return (
        <React.Fragment>
          <RiskDetails
            generalHelperClass={props.generalHelperClass}
            languageHelperClass={props.languageHelperClass}
            trafficSumProp={
              Number(trafficSum.toFixed(4)) +
              Number(
                props.sectionsHelperClass.trafficRestRiskValue(
                  selectedAge,
                  selectedSex
                )
              )
            }
            leisureSumProp={Number(leisureSum.toFixed(4))}
            averageSumProp={Number(
              props.sectionsHelperClass
                .meinRestRiskValue(selectedAge, selectedSex)
                .toFixed(4)
            )}
            onRecalculate={() => {
              resetLeisureButtonPressed();
              resetTrafficButtonPressed();
              SmoothScroll.scrollTo("header");
            }}
            selectedAge={selectedAge}
            selectedSex={selectedSex}
          ></RiskDetails>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
};

Risikorechner.propTypes = {
  riskCalcJson: PropTypes.array,
  riskDetailsJson: PropTypes.array,
  sectionsHelperClass: PropTypes.object.isRequired,
  generalHelperClass: PropTypes.object.isRequired,
  leisureSectionsHelperClass: PropTypes.object.isRequired,
  configHelperClass: PropTypes.object.isRequired,
  relationChartHelperClass: PropTypes.object.isRequired,
  languageHelperClass: PropTypes.object.isRequired,
  entityHelper: PropTypes.object.isRequired,
  riskHelper: PropTypes.object.isRequired,
};

export default Risikorechner;
