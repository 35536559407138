import Button from "components/Button/Button";
import Dropdown from "components/Dropdown/Dropdown";
import PropTypes from "prop-types";
import React from "react";
import "./widget.less";
import HoverElement from "components/HoverElement/HoverElement";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import { Card } from "@mui/material";

const Widget = (props) => {
  // const sexOptions = props.configHelperClass.sexOptions;

  // const ageOptions = props.configHelperClass.ageOptions;

  function handleSexClicked(sex) {
    props.onSexSelected(sex);
  }
  function handleAgeClicked(age) {
    // console.log("Click age:");
    // console.log(age);
  }

  return (
    <React.Fragment>
      {/* {props.riskHelper.entityHelper.taetigkeiten()[0].Tätigkeit} */}
        <Element name="risk-hero" className="element">
        <Card variant="outlined" className="hero-widget">
          <h1>Risikorechner</h1>
          <h5>
            {props.riskHelper.configHelperClass.heroText().header}{" "}
            <HoverElement
              generalHelperClass={props.riskHelper.generalHelperClass}
              hoverLabel="Verletzungsrisiko"
            >
              Verletzungsrisiko! 
            </HoverElement>
          </h5>
          <p>
            Ihr Risiko wird in Relation zu den durchschnittlichen Risiken der in{" "}
            <HoverElement
              generalHelperClass={props.riskHelper.generalHelperClass}
              hoverLabel="Durchschnitt"
            >
              Österreich
            </HoverElement>{" "}
            lebenden Menschen berechnet. In Österreich wird man im Durchschnitt
            alle 20 Jahre aufgrund eines Unfalles im Krankenhaus behandelt.
          </p>
          <div className="row">
            {/* <div className="column sex-wrapper">
              <label className="sex-label">Geschlecht:</label>
              <Dropdown
                // list={props.riskHelper.configHelperClass.sexOptions()}
                list={props.riskHelper.entityHelper.geschlechtDropDown()}
                onItemClick={(sex) => props.onSexSelected(sex)}
                onSelectedItem={(sex) => props.onSexSelected(sex)}
                selectedItem={1}
              />
            </div>
            <div className="column age-wrapper">
              <label>Alter:</label>
              <Dropdown
                generalHelperClass={props.riskHelper.generalHelperClass}
                list={props.riskHelper.configHelperClass.ageOptions()}
                onItemClick={(age) => props.onAgeSelected(age)}
                onSelectedItem={(age) => props.onAgeSelected(age)}
                selectedItem={1}
              />
            </div> */}
          </div>
          <div className="row calculate-button">
            {/* <Button
              label={props.riskHelper.languageHelperClass.languageJson.hero.startButton}
              handleClick={props.onCalculatePressed}
            /> */}
          </div>
          </Card>
        </Element>
    </React.Fragment>
  );
};

Widget.propTypes = {
  onAgeSelected: PropTypes.func,
  onSexSelected: PropTypes.func,
  onCalculatePressed: PropTypes.func,
  riskHelper: PropTypes.object,
};

export default Widget;
