// import * as GeneralHelper from "helper/GeneralHelper";

class RelationChartHelperClass {
  leisureDataset = {};

  relationJsonArray = null;
  configJson = null;
  languageHelperClass = null;

  constructor(jsonArray, configJson, languageHelperClass) {
    this.relationJsonArray = jsonArray;
    this.configJson = configJson;
    this.languageHelperClass = languageHelperClass;
  }

  relationTrafficObject() {
    return this.relationJsonArray[0].content;
  }

  relationLeisureObject() {
    return this.relationJsonArray[1].content;
  }

  getDatasetForLeisureBubble() {
    return this.getDatasetForBubble(this.relationLeisureObject());
  }

  getDatasetForTrafficBubble() {
    return this.getDatasetForBubble(this.relationTrafficObject());
  }

  // x: 0
  // y: 1
  // type: 0 = Verkehr, 1 = Freizeit
  getAxesLabelFor(axes, type) {
    switch (axes) {
      case 0:
        // ~~~~~~~~~~~~~~~~ x axes ~~~~~~~~~~~~~~~ //
        if (type === 0) {
          // Verkehr
          return "Wenige Verletzte pro Stunde → Viele Verletzte pro Stunde";
        } else {
          // Freizeit
          return "Geringes Verletzungsrisiko → Hohes Verletzungsrisiko";
        }
      case 1:
        // ~~~~~~~~~~~~~~~~ y axes ~~~~~~~~~~~~~~~ //
        if (type === 0) {
          // Verkehr
          return "Wenige Stunden → Viele Stunden";
        } else {
          // Freizeit
          return  "Wenige Ausübende → Viele Ausübende";
        }
      default:
        return;
    }
  }



  // getDatasetForBubble(array) {
  //   var data = [];
  //   array.forEach((element, index) => {
  //     var singleDataSet = {
  //       id: element.id,
  //       Taetigkeit: element.Taetigkeit,
  //       x: element.VerletzungenPro100K,
  //       y: element.Ausuebende,
  //       r: 12,
  //       backgroundColor: this.calcBackgroundColorFor(
  //         element.Verletzungsschwere
  //       ),
  //     };

  //     // single dataset
  //     data.push({
  //       label: element.Taetigkeit,
  //       data: [singleDataSet],
  //       backgroundColor: this.calcBackgroundColorFor(
  //         element.Verletzungsschwere
  //       ),
  //       info: element.info
  //     });
  //   });

  //   return data;
  // }

  getDatasetForBubbleNew(array, selectedDropDownValues) {
    var selectedIds = selectedDropDownValues.flatMap((value) => value.id);
    var data = [];
    array.forEach((element, index) => {
      var singleDataSet = {
        id: element.Taetigkeit_ID,
        Taetigkeit: element.Taetigkeit,
        x: element.X,
        y: element.Y,
        r: 20,
        // backgroundColor: this.calcBackgroundColorFor(
        //   element.Schwere
        // ),
      };


      let isActive = selectedIds.indexOf(element.Taetigkeit_ID) >= 0;
      let bgColor  = this.calcBackgroundColorFor(
        element.Schwere,
        isActive
      );
      let activeBgColor = this.calcBackgroundColorFor(
        element.Schwere,
        true
      );

      let bWidth = isActive ? 1 : 0;
      let bColor = isActive ? '#333' : '';

      



      // console.log(element.Taetigkeit_ID + ': ' + singleDataSet);
      // single dataset
      data.push({
        label: element.Taetigkeit,
        data: [singleDataSet],
        backgroundColor: bgColor,
        borderColor: bColor,
        borderWidth: bWidth,
        hoverBackgroundColor: activeBgColor,
        hoverBorderColor: "#333",
        hoverBorderWidth: 1,
        // info: element.info
      });
    });

    return data;
  }



  calcBackgroundColorFor(number, isActive) {
    let injuries = this.configJson.relationChartInjuries;
    let foundElement = injuries.find((element) => {
      return Number(element.value) === Number(number);
    });

    return isActive ? foundElement.color : foundElement.colorInactive;
    
  }


  prepareLegendHtmlString() {
    let injuries = this.configJson.relationChartInjuries;

    var htmlArray = [];
    htmlArray.push("<ul>");
    injuries.forEach((element, index) => {
      htmlArray.push("<li>");
      htmlArray.push(
        '<span class="chart-legend" style="background-color:' +
          element.color +
          '"></span>'
      );
      htmlArray.push(
        '<span class="chart-legend-label-text">' + element.label + "</span>"
      );
      htmlArray.push("</li>");
    });

    htmlArray.push("</ul>");
    return htmlArray.join("");
  }
}

export default RelationChartHelperClass;
