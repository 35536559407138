class RiskHelper {
  generalHelperClass = null;
  sectionsHelperClass = null;
  leisureSectionsHelperClass = null;
  configHelperClass = null;
  languageHelperClass = null;
  relationChartHelperClass = null;
  entityHelper = null;

  

  constructor(generalHelperClass, sectionsHelperClass, leisureSectionsHelperClass, configHelperClass, languageHelperClass, relationChartHelperClass, entityHelper) {
    this.generalHelperClass = generalHelperClass;
    this.sectionsHelperClass = sectionsHelperClass;
    this.leisureSectionsHelperClass = leisureSectionsHelperClass;
    this.configHelperClass = configHelperClass;
    this.languageHelperClass = languageHelperClass;
    this.relationChartHelperClass = relationChartHelperClass;
    this.entityHelper = entityHelper;
  }

  heroText() {
    return this.configJson.hero;
  }

  sexOptions() {
    return this.configJson.sexOptions;
  }

  ageOptions() {
    return this.configJson.ageOptions;
  }

  relationChartInfoTraffic() {
    return this.configJson.relationChartInfoTraffic;
  }

  relationChartInfoLeisure() {
    return this.configJson.relationChartInfoLeisure;
  }

  relationTrafficTitle() {
    return this.configJson.relationChartTrafficHeader;
  }

  relationLeisureTitle() {
    return this.configJson.relationChartLeisureHeader;
  }

  /**
   * @returns Array of injury objects
   */
  relationInjuries() {
    return this.configJson.relationChartInjuries;
  }
}
export default RiskHelper;
