import React from "react";
import PropTypes from "prop-types";
import "./Dropdown.less";

class Dropdown extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      labelItem: null,
      typeDropdown: null,
      placeholderText: props.placeholder
    };

    if(this.props.selectedItem != undefined && this.props.onSelectedItem != undefined) {
      // Don't call onSelectedItem if selectedItem is set manually. For initial
      this.props.onSelectedItem(this.props.selectedItem, this.props.list[this.props.selectedItem])
    }
  }

  UNSAFE_componentWillMount() {
    var { label } = 
      this.props.selectedItem != undefined
        ? this.props.list[Number(this.props.selectedItem)]
        : this.props.list[0];

    let firstItem = null;
    if (typeof label != "undefined") {
      this.checkType(false);
      firstItem = label;
    } else {
      this.checkType(true);
      firstItem = this.props.list[0];
    }
    this.setState({
      labelItem: firstItem
    });
  }
  checkType = value => {
    this.setState({
      typeDropdown: value
    });
  };
  showDropdown = () => {
    this.setState({ isOpen: true });
    const rootNode = document.getElementById('root');
    rootNode.addEventListener("click", this.hideDropdown);
  };
  hideDropdown = () => {
    this.setState({ isOpen: false });
    const rootNode = document.getElementById('root');
    rootNode.removeEventListener("click", this.hideDropdown);
  };
  chooseItem = (value, index) => {
    if (this.state.labelItem !== value) {
      this.setState({
        labelItem: value
      });
    }

    // reset Placeholder so it's not shown after first added item
    this.setState({
      placeholderText: undefined
    });

    // call click function
    this.props.onItemClick(index, this.props.list[index]);
  };

  renderDataDropDown = (item, index) => {
    const { value, label } = this.state.typeDropdown
      ? { value: index, label: item }
      : item;
    return (
      <li
        key={index}
        value={value}
        class={this.addDisabledClass(value)}
        onClick={() => this.chooseItem(label, index)}
      >
        <a>{label}</a>
      </li>
    );
  };


  // Check if already added
  addDisabledClass(value) {
    if(this.props.addedIds != undefined && 
      this.props.addedIds.length > 0 && 
      (this.props.addedIds.some((val) => val == value) || this.props.addedIds.some((val) => val == value.id))) {
        return "disabled";
    }

    return "";
  }

  truncate(str, no_of_words, truncationSuffix = "...") {
    var truncation = "";
    if (str.split(" ").length > no_of_words) {
      truncation = truncationSuffix;
    }

    return str.split(" ").splice(0, no_of_words).join(" ") + truncation;
  }

  render() {
    const { list } = this.props;
    return (
      <div className={`dropdown ${this.state.isOpen ? "open" : ""}`}>
        <button
          className="dropdown-toggle"
          type="button"
          onClick={this.showDropdown}
        >
          {/* {this.state.labelItem} */}
          {this.state.placeholderText != undefined ? this.state.placeholderText : this.truncate(this.state.labelItem, 3)}
          <span className="caret"></span>
        </button>
        <ul className="dropdown-menu">{list.map(this.renderDataDropDown)}</ul>
      </div>
    );
  }
}

Dropdown.propTypes = {
  list: PropTypes.array.isRequired,
  onItemClick: PropTypes.func, // (index, value)
  onSelectedItem: PropTypes.func, // (index, value)
  selectedItem: PropTypes.number,
  addedIds: PropTypes.array,
  placeholder: PropTypes.string,
};

export default Dropdown;
