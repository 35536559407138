// import * as GeneralHelper from "helper/GeneralHelper";

class EntityHelper {
  entity = null;
  taetigkeitenDropDownArray = null;
  taetigkeitenArray = null;

  geschlechtDropDownArray = null;
  alterDropDownArray = null;

  constructor(entity) {
    this.entity = entity;
    this._mapTaetigkeiten();
    this._mapGeschlecht();
    this._mapAltersgruppen();
  }
  _mapTaetigkeiten() {
    let taetigkeiten = this.entity.taetigkeiten.map((val) => ({
      id: Number(val.Tätigkeit_ID),
      selectedValue: val.Regler_Presetting ? val.Regler_Presetting : -1,
      Bereich: "Verkehr",
      Taetigkeit: val.Tätigkeit,
      Risiko_m_15_24: 0.016,
      Risiko_w_15_24: 0.008,
      Risiko_m_25_44: 0.011,
      Risiko_w_25_44: 0.007,
      Risiko_m_45_64: 0.0125,
      Risiko_w_45_64: 0.01,
      Risiko_m_65plus: 0.013,
      Risiko_w_65plus: 0.015,
      Risiko_Mittel: 0.005,
      Regler_Presetting: "0",
      color: val.color,
      IsDefault: val.IsDefault,
      hover: val.hover,
      icon:val.icon
    }));

    this.taetigkeitenArray = taetigkeiten;

    let dropDownVals = taetigkeiten.map((val) => ({
      value: val,
      label: val.Taetigkeit,
    }));

    this.taetigkeitenDropDownArray = dropDownVals;
this.taetigkeitenDefaultArray();
console.log("ddfs");
  }


  // ##################################################################### //
  // ############################ Tätigkeiten ############################ //
  // ##################################################################### //
  taetigkeiten() {
    return this.entity.taetigkeiten;
  }
  taetigkeitenDropDown() {
    return this.taetigkeitenDropDownArray;
  }

  taetigkeitForId(id) {
    return this.taetigkeitenArray.find((element) => {
      return element.id == id;
    });
  }

  taetigkeitenDefaultDropDownArray() {
    return this.taetigkeitenDropDownArray.filter(function (obj) {
      return obj.value.IsDefault !== null;
    })
  }

  taetigkeitenDefaultArray() {
    return this.entity.taetigkeiten.filter(function (obj) {
      return obj.IsDefault !== null;
    })
  }

  // ##################################################################### //
  // ############################# Geschlecht ############################ //
  // ##################################################################### //

  _mapGeschlecht() {
    function translateSex(sexShort) {
      if (sexShort == "w") {
        return "Weiblich";
      } else if (sexShort == "m") {
        return "Männlich";
      } else {
        return "Divers";
      }
    }

    const dropDownValues = this.entity.geschlecht.map((val) => ({
      value: Number(val.Geschlecht_ID),
      label: translateSex(val.Geschlecht),
    }));

    this.geschlechtDropDownArray = dropDownValues;
  }

  geschlechtDropDown() {
    return this.geschlechtDropDownApi();
  }

  geschlechtDropDownApi() {
    return this.geschlechtDropDownArray;
  }

  // ##################################################################### //
  // ########################### Altersgruppen ########################### //
  // ##################################################################### //

  _mapAltersgruppen() {
    /*
     *    { "value": "0", "label": "15 - 24" },
     *    { "value": "1", "label": "25 - 44" },
     *    { "value": "2", "label": "45 - 64" },
     *    { "value": "3", "label": "65 +" }
     */
    const dropDownValues = this.entity.altersgruppen.map((val) => ({
      value: Number(val.Alter_ID),
      label: val.Alter_txt,
    }));

    this.alterDropDownArray = dropDownValues;
  }

  altersgruppenDropDown() {
    return this.alterDropDownArray;
  }

  sparten() {
    return this.entity.sparten;
  }
}

export default EntityHelper;
