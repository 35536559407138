import React, { useState } from "react";
import PropTypes from "prop-types";
import "./rangeslider.less";
// import sectionsJson from "assets/json/risiko_calc";
import HTMLTippy from "components/HTMLTippy/HTMLTippy";
// import HoverElement from "components/HoverElement/HoverElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaInfoCircle } from "react-icons/all";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import parse from "html-react-parser";


import {
  LightWinterIcon,
  LightBatIcon,
  LightBikeIcon,
  LightCarIcon,
  LightHikeIcon,
  LightPredestianIcon,
  LightPublicTrafficIcon,
  LightRunIcon,
  LightSkateIcon2,
  LightSoccerIcon,
  LightSoccerIcon2,
  LightWaterIcon,
  LightMotorbikeIcon,
} from "components/ActivityIcons/light";

import {
  SolidWinterIcon,
  SolidBatIcon,
  SolidBikeIcon,
  SolidCarIcon,
  SolidHikeIcon,
  SolidPredestianIcon,
  SolidPublicTrafficIcon,
  SolidRunIcon,
  SolidSkateIcon,
  SolidSkateIcon2,
  SolidSoccerIcon,
  SolidSoccerIcon2,
  SolidWaterIcon,
  SolidAdventureIcon,
  SolidMotorbikeIcon,
} from "components/ActivityIcons/solid";

class Rangeslider extends React.Component {
  baseValue = this.props.initSelected;
  constructor(props) {
    super(props);
    this.state = { value: this.props.initSelected };
    // const baseValue = this.props.initSelected;
  }

  handleChange(event, index) {
    this.setState({ value: event.target.value });
  }

  mouseUpEvent(event, id) {
    this.props.onRelease(event.target.value, id);
  }

  resetSlider() {
    this.setState({ value: this.baseValue });
    this.props.onRelease(this.baseValue);
  }
  sectionIdValue() {
    console.log(this.props.initSelected);
    let foundSection = this.props.sectionsHelperClass.riskCalcJsonArray.find(
      (element) => element.id === this.props.section
    );
    let foundValue = foundSection.content.find(
      (element) => element.id === this.props.index
    );
    // setState({ value: Number(foundValue.Regler_Presetting) });
    return Number(foundValue.Regler_Presetting);
  }

  getActivityIconSolid() {
    switch (this.props.section) {
      case 0: // Straßenverkehr
        switch (this.props.taetigkeitId) {
          case 0:
            return <SolidPredestianIcon></SolidPredestianIcon>;
          case 1:
            return <SolidBikeIcon></SolidBikeIcon>;
          case 2:
            return <SolidMotorbikeIcon></SolidMotorbikeIcon>;
          case 3:
            return <SolidCarIcon></SolidCarIcon>;
          case 4:
            return <SolidPublicTrafficIcon></SolidPublicTrafficIcon>;
          default:
            console.error("no icon id");  
            return <SolidPublicTrafficIcon></SolidPublicTrafficIcon>;
        }
        break;
      case 1: // Freizeit
        switch (this.props.taetigkeitId) {
          case 0:
            return <SolidWinterIcon></SolidWinterIcon>;
          case 1:
            return <SolidSoccerIcon2></SolidSoccerIcon2>;
          case 2:
            return <SolidBatIcon></SolidBatIcon>;
          case 3:
            return <SolidHikeIcon></SolidHikeIcon>;
          case 4:
            return <SolidRunIcon></SolidRunIcon>;
          case 5:
            return <SolidWaterIcon></SolidWaterIcon>;
          case 6:
            return <SolidAdventureIcon></SolidAdventureIcon>;
          case 7:
            return <SolidSkateIcon2></SolidSkateIcon2>;
          default:
            console.error("no icon id");
            return <SolidSkateIcon2></SolidSkateIcon2>;
        }
        break;
      default:
        console.error("no section");
        break;
    }
  }
  getActivityIconLight() {
    switch (this.props.section) {
      case 0: // Straßenverkehr
        switch (this.props.taetigkeitId) {
          case 0:
            return <LightPredestianIcon></LightPredestianIcon>;
          case 1:
            return <LightBikeIcon></LightBikeIcon>;
          case 2:
            return <LightMotorbikeIcon></LightMotorbikeIcon>;
          case 3:
            return <LightCarIcon></LightCarIcon>;
          case 4:
            return <LightPublicTrafficIcon></LightPublicTrafficIcon>;
          default:
            console.error("no icon id");
            break;
        }
        break;
      case 1: // Freizeit
        switch (this.props.taetigkeitId) {
          case 0:
            return <LightWinterIcon></LightWinterIcon>;
          case 1:
            return <LightSoccerIcon2></LightSoccerIcon2>;
          case 2:
            return <LightBatIcon></LightBatIcon>;
          case 3:
            return <LightHikeIcon></LightHikeIcon>;
          case 4:
            return <LightRunIcon></LightRunIcon>;
          case 5:
            return <LightWaterIcon></LightWaterIcon>;
          case 6:
            return <SolidAdventureIcon></SolidAdventureIcon>;
          case 7:
            return <LightSkateIcon2></LightSkateIcon2>;
          default:
            console.error("no icon id");
            break;
        }
        break;
      default:
        console.error("no section");
        break;
    }
  }

  getApiImageIconUrl() {
    var url;
    try {
        url = process.env.REACT_APP_BACKEND_URL + "admin/icons/"  + this.props.iconName;
        // url = require("assets/images/icons/" + url);
    } catch (e) {
      console.error("Remote Icon not found!")
        url = process.env.REACT_APP_BACKEND_URL + "admin/icons/Fallback.svg";
        // url = require("assets/images/icons/Fallback.svg");
    }

    console.log(`UUUUTTTTT: ${this.props.iconName}`);
    
    return url;
  }

  render() {
    var title = this.props.label;
    if (
      this.props.hoverContent != undefined &&
      this.props.hoverContent.length > 0
    ) {
      title = (
        <React.Fragment>
          {this.props.label}

         
        </React.Fragment>
      );
    }
  
    var icon = (
      <React.Fragment>
        <Tippy
          content={parse(this.props.hoverContent)}
          interactive={true}
          placement={"bottom"}
          zIndex={9999}
        >
          <span className="activity-icon ml-3">
            {/* {this.getActivityIconLight()} */}
            {/* {this.getActivityIconSolid()} */}
            <img
              src={this.getApiImageIconUrl()}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = require("assets/images/icons/Fallback.svg");
              }}
              alt=""
              width={70}
              height={70}
            />
          </span>
        </Tippy>
      </React.Fragment>
    );

    return (
      <div className="d-flex flex-row rangeslider-wrapper">
        <div className="rangeslider">
          <label className="sliderlabel">
            <span className="title">{title}</span>
          </label>

          <span className="inputlabel left">nie</span>
          <span className="inputlabel right">sehr oft</span>
          <br />
          <label>
            <input
              style={{ background: this.props.lineColor }}
              id={this.props.id}
              type="range"
              min="-2"
              max="2"
              value={this.state.value}
              onChange={(value) => this.handleChange(value)}
              onMouseUp={(value) =>
                this.mouseUpEvent(value, this.props.taetigkeitId)
              }
              onTouchEnd={(value) =>
                this.mouseUpEvent(value, this.props.taetigkeitId)
              }
              step="1"
              className="rangeslider"
            />
          </label>
        </div>
        {icon}
      </div>
    );
  }
}

Rangeslider.propTypes = {
  sectionsHelperClass: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  initSelected: PropTypes.number.isRequired,
  onRelease: PropTypes.func,
  lineColor: PropTypes.string,
  hoverContent: PropTypes.string,
  section: PropTypes.number,
  taetigkeitId: PropTypes.number,
  iconName:PropTypes.string
};

Rangeslider.defaultProps = {
  initSelected: 0,
};

export default Rangeslider;
