import React, { Component } from "react";
import "./footer.less";
// import { CookieBanner } from "@palmabit/react-cookie-law";
class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer>
          <div className="main-footer wrapper">
            <div className="row">
              <aside className="footer-logo">
                <a href="https://www.kfv.at/">
                  <img
                    src="https://www.kfv.at/wp-content/uploads/2018/09/logo-kfv-ohne-schrift2.svg"
                    alt="KFV"
                  />
                </a>
              </aside>
              <aside className="footer-text-wrap">
                SAFETY FIRST!
                <br />
                <br />
                Kontaktieren Sie uns:
                <br />
                E-Mail: <a href="mailto:kfv@kfv.at">kfv@kfv.at</a>
                <br />
                Telefon: <a href="tel:+435770770">+43 5 770 77 – 0</a>
              </aside>
            </div>
          </div>
          <div className="sub-footer">
            <div className="wrapper">
              <div className="row">
                <p>&copy; KFV 2020</p>
                <ul>
                  <li>
                    <a
                      href="https://kfv.at/kontakt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Kontakt
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://static.kfv.at/datenschutz"
                    >
                      Datenschutz
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://static.kfv.at/impressum/"
                    >
                      Impressum
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.kfv.at/das-kfv/karriere/"
                    >
                      Karriere
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
