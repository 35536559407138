import React, { useState, useEffect, useRef, createRef } from "react";
import Select, { components } from "react-select";
import { AlertTitle, Alert, Divider } from "@mui/material";

import PropTypes from "prop-types";

// import sectionsJson from "assets/json/risiko_calc";
import Accordion from "components/Collapsible/Accordion";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import Rangeslider from "components/Rangeslider/Rangeslider";
import RiskDetails from "components/RiskDetails/RiskDetails";
import Sidebar from "components/Sidebar/Sidebar";
import Widget from "components/Widget/Widget";
import RelationChartJS from "components/RelationChartJS/RelationChartJS";
import { Bar } from "react-chartjs-2";
import "styles/main.less";

import GeneralHelperClass from "helper/GeneralHelper";

import SmoothScroll from "components/SmoothScroll/SmoothScroll";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaInfoCircle } from "react-icons/all";
import ReactHtmlParser from "react-html-parser";
import Button from "components/Button/Button";
import parse from "html-react-parser";
import HTMLTippy from "components/HTMLTippy/HTMLTippy";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import EntityHelper from "helper/EntityHelper";
import Dropdown from "components/Dropdown/Dropdown";


var barOptions = {
  tooltips: {
    enabled: true,
    mode: "point", // mode: "x",
    callbacks: {
      label: GeneralHelperClass.removeNumberInTooltip,
    },
  },
  legend: {
    display: false,
    position: "right",
    align: "start",
    labels: {
      padding: 12,
      boxWidth: 10,
      fontSize: 11,
      filter: (legendItem, chartData) => {
        return (
          chartData.datasets[legendItem.datasetIndex].stack != "Durchschnitt"
        );
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: { display: false },
        stacked: true,
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: { display: false },
        stacked: true,
      },
    ],
  },
};

var barOptionsLeisure = {
  tooltips: {
    enabled: true,
    mode: "point", // mode: "x",
    callbacks: {
      label: GeneralHelperClass.removeNumberInTooltip,
    },
  },
  legend: {
    display: false,
    position: "bottom",
    align: "start",
    labels: {
      padding: 12,
      boxWidth: 10,
      fontSize: 11,
      filter: (legendItem, chartData) => {
        return (
          chartData.datasets[legendItem.datasetIndex].stack != "Durchschnitt"
        );
      },
    },
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: { display: false },
        stacked: true,
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: { display: false },
        stacked: true,
      },
    ],
  },
};

const RisikorechnerNeu = (props) => {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    window.addEventListener("resize", handleWindowSizeChange);
  });

  function handleWindowSizeChange() {
    // setScreenWidth(window.innerWidth);
    var newIsMobile = window.innerWidth <= 500 ? true : false;
    if (isMobile != newIsMobile) {
      setIsMobile(newIsMobile);
    }
  }

  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= 500 ? true : false
  );

  function resetTrafficButtonPressed() {
    trafficRefs.current.forEach((element) => {
      if (element.current != null) {
        element.current.resetSlider();
      }
    });

    setDataTrafficBar(
      props.sectionsHelperClass.initTrafficState(selectedAge, selectedSex)
    );
  }

  function resetLeisureButtonPressed() {
    leisureRefs.current.forEach((element) => {
      if (element.current != null) {
        element.current.resetSlider();
      }
    });

    setDataLeisureBar(
      props.leisureSectionsHelperClass.initLeisureState(
        selectedAge,
        selectedSex
      )
    );
  }

  const trafficRefs = useRef(
    props.sectionsHelperClass.trafficObject().content.map(() => createRef())
  );
  const leisureRefs = useRef(
    props.leisureSectionsHelperClass
      .leisureObject()
      .content.map(() => createRef())
  );

  // ##################################################################### //
  // ######################### TRAFFIC PROPERTIES ######################## //
  // ##################################################################### //
  // User selects a slider in traffic -> reload data
  const [selectedTrafficState, setSelectedTrafficState] = useState({}); // {id: 0, value: 0.022}
  useEffect(() => {
    if (selectedTrafficState.id != undefined) {
      var newDataTrafficBar = props.sectionsHelperClass.calculateTrafficState(
        selectedSex,
        selectedAge,
        selectedTrafficState,
        dataTrafficBar
      );

      setDataTrafficBar(newDataTrafficBar);
    }
  }, [selectedTrafficState]);

  const [trafficSum, setTrafficSum] = useState(0.0);
  useEffect(() => {
    // selected state updated
    // console.log(`Changed traffic: ${trafficSum} `);
  }, [trafficSum]);

  // The current traffic data array
  const [dataTrafficBar, setDataTrafficBar] = useState(
    props.sectionsHelperClass.trafficDataset
  );
  useEffect(() => {
    if (
      dataTrafficBar != undefined &&
      dataTrafficBar.datasets != undefined &&
      dataTrafficBar.datasets.length > 0
    ) {
      let sum = props.sectionsHelperClass.trafficSumFromDataset(dataTrafficBar);
      setTrafficSum(sum);
    }
  }, [dataTrafficBar]);

  // ##################################################################### //
  // ######################### LEISURE PROPERTIES ######################## //
  // ##################################################################### //
  const [selectedLeisureState, setSelectedLeisureState] = useState({}); // {id: 0, value: 0.022}
  useEffect(() => {
    console.log('selectedLeisureState changed');
    // if (selectedLeisureState.id != undefined) {
    //   var newDataLeisureBar =
    //     props.leisureSectionsHelperClass.calculateLeisureState(
    //       selectedSex,
    //       selectedAge,
    //       selectedLeisureState,
    //       dataLeisureBar
    //     );

    //   setDataLeisureBar(newDataLeisureBar);
    // }
  }, [selectedLeisureState]);

  const [leisureSum, setLeisureSum] = useState(0.0);
  useEffect(() => {
    // selected state updated
  }, [leisureSum]);

  // The current leisure data array
  const [dataLeisureBar, setDataLeisureBar] = useState(
    props.leisureSectionsHelperClass.leisureDataset
  );
  useEffect(() => {
    if (
      dataLeisureBar != undefined &&
      dataLeisureBar.datasets != undefined &&
      dataLeisureBar.datasets.length > 0
    ) {
      let sum =
        props.leisureSectionsHelperClass.leisureSumFromDataset(dataLeisureBar);
      setLeisureSum(sum);
    }
  }, [dataLeisureBar]);

  // ##################################################################### //
  // ######################### GENERAL PROPERTIES ######################## //
  // ##################################################################### //

  const [selectedAge, setSelectedAge] = useState(-1);
  useEffect(() => {
    console.log(`selectedAge: ${selectedAge} `);
    calculateRiskAPI();

    // if (selectedLeisureState.id != undefined) {
    // var newDataLeisureBar =
    //   props.leisureSectionsHelperClass.calculateLeisureState(
    //     selectedSex,
    //     selectedAge,
    //     selectedLeisureState,
    //     dataLeisureBar
    //   );

    // setDataLeisureBar(newDataLeisureBar);
    // }

    // if (selectedTrafficState.id != undefined) {
    // var newDataTrafficBar = props.sectionsHelperClass.calculateTrafficState(
    //   selectedSex,
    //   selectedAge,
    //   selectedTrafficState,
    //   dataTrafficBar
    // );

    // setDataTrafficBar(newDataTrafficBar);
    // }
  }, [selectedAge]);

  const [selectedSex, setSelectedSex] = useState(-1);
  useEffect(() => {
    console.log(`selectedSex: ${selectedSex} changed`);
    // setDataTrafficBar(
    //   props.sectionsHelperClass.initTrafficState(selectedAge, selectedSex)
    // );

    // setDataLeisureBar(
    //   props.leisureSectionsHelperClass.initLeisureState(
    //     selectedAge,
    //     selectedSex
    //   )
    // );
    calculateRiskAPI();

    // if (selectedLeisureState.id != undefined) {
    //   var newDataLeisureBar =
    //     props.leisureSectionsHelperClass.calculateLeisureState(
    //       selectedSex,
    //       selectedAge,
    //       selectedLeisureState,
    //       dataLeisureBar
    //     );

    //   setDataLeisureBar(newDataLeisureBar);
    // }

    // if (selectedTrafficState.id != undefined) {
    //   var newDataTrafficBar = props.sectionsHelperClass.calculateTrafficState(
    //     selectedSex,
    //     selectedAge,
    //     selectedTrafficState,
    //     dataTrafficBar
    //   );

    //   setDataTrafficBar(newDataTrafficBar);
    // }
  }, [selectedSex]);

  const [showRiskDetails, setShowRiskDetails] = useState(true);
  const [showRiskCalculateContent, setShowRiskCalculateContent] =
    useState(true);


  function test() {
    // props.riskHelper.entityHelper.taetigkeitForId(value.value.id)
    let initialSelectedValues =  props.entityHelper.taetigkeitenDefaultArray().flatMap(
      (item) => {
          return props.riskHelper.entityHelper.taetigkeitForId(Number(item.Tätigkeit_ID));
      }
  );
    
    return initialSelectedValues;
  }

  /** for "entitäten" */
  const [selectedDropDownValues, setSelectedDropDownValues] = useState(test());
  useEffect(() => {
    console.log(`selectedDropDownValue: ${selectedDropDownValues} `);
    console.log(
      `selectedDropDownValueJSON: ${JSON.stringify(selectedDropDownValues)} `
    );
    console.log(
      `selectedDropDownValueLength: ${selectedDropDownValues.length} `
    );

    calculateRiskAPI();
  }, [selectedDropDownValues]);

  const [apiResult, setApiResult] = useState({});
  useEffect(() => {
    console.log(`apiResult: ${apiResult} `);
    console.log(`apiResultJSON: ${JSON.stringify(apiResult)} `);
  }, [apiResult]);

  return (
    <React.Fragment>
      <div className="fullwidth widget main-content">
        <Widget
          riskHelper={props.riskHelper}
          onAgeSelected={(value) => setSelectedAge(value)}
          onSexSelected={(value) => setSelectedSex(value)}
          onCalculatePressed={() => {
            resetLeisureButtonPressed();
            resetTrafficButtonPressed();
            setDataTrafficBar(
              props.sectionsHelperClass.initTrafficState(
                selectedAge,
                selectedSex
              )
            );

            setDataLeisureBar(
              props.leisureSectionsHelperClass.initLeisureState(
                selectedAge,
                selectedSex
              )
            );

            setShowRiskCalculateContent(true);
            // setTimeout(function () {
            //   SmoothScroll.scrollTo("main-content-anchor");
            // }, 300);
            setTimeout(function () {
              // scroller.scrollTo({
              //   to: "risk-calculation",
              // });
              scroller.scrollTo("risk-calculation", {
                smooth: true,
                offset: -150,
              });
            }, 300);
          }}
        />
      </div>

      <main>
        {RiskCalculateContent()}
        {RiskDetailsContent()}
      </main>

      <Footer />
    </React.Fragment>
  );

  function RelationChart(type) {
    return (
      <RelationChartJS
        relationChartHelperClass={props.relationChartHelperClass}
        generalHelperClass={props.generalHelperClass}
        entityHelper={props.entityHelper}
        riskHelper={props.riskHelper}
        type={type}
        vergleichsHelper={props.vergleichsHelper}
        selectedDropDownValues={selectedDropDownValues}
      ></RelationChartJS>
    );
  }

  function RiskCalculateContent() {
    if (showRiskCalculateContent) {
      return (
        <React.Fragment>
          <div className="fullwidth">
            <h1>Wie hoch ist Ihr Risiko?</h1>
          </div>
          <div className="wrapper risk-bars-main">
            <div id="main-content-anchor" className="main">
              <Element name="risk-calculation" className="element">
                <Element name="risk-calculation-general" className="element">
                  <div className="row accordion-row mb-3">
                    {/* ------- Column 1 -------  */}
                    <div className="double-column mr-4">
                      <div className="accordion-slider">
                        <div className="row calculation-row">
                          <div className="mr-3 sex-wrapper">
                            <label className="sex-label dropdown-label pb-1 font-bold">
                              Geschlecht:
                            </label>
                            <Select
                              placeholder={"Auswählen..."}
                              options={props.riskHelper.entityHelper.geschlechtDropDown()}
                              isClearable={false}
                              isMulti={false}
                              // defaultValue={
                              //   props.riskHelper.entityHelper.geschlechtDropDown()[0]
                              // }
                              onChange={(sex) => {
                                console.log("sex:" + sex.value);
                                setSelectedSex(sex.value);
                              }}
                              // defaultValue={
                              //   props.riskHelper.entityHelper.geschlechtDropDown()[1]
                              // }
                            />

                            {/* <Dropdown
                              // list={props.riskHelper.configHelperClass.sexOptions()}
                              list={props.riskHelper.entityHelper.geschlechtDropDown()}
                              onItemClick={(sex) => {
                                console.log("sex:" + sex);
                                setSelectedSex(sex);
                              }}
                              onSelectedItem={(sex) => {
                                console.log("sex:" + sex);
                                setSelectedSex(sex);
                              }}
                              selectedItem={1}
                            /> */}
                          </div>
                          <div className="mr-3 age-wrapper">
                            <label className="font-bold pb-1 dropdown-label">
                              Alter:
                            </label>
                            <Select
                              placeholder={"Auswählen..."}
                              // options={props.riskHelper.configHelperClass.ageOptions()}
                              options={props.entityHelper.altersgruppenDropDown()}
                              isClearable={false}
                              isMulti={false}
                              // defaultValue={
                              //   props.riskHelper.configHelperClass.ageOptions()[0]
                              // }
                              onChange={(age) => {
                                console.log("age:" + age.value);
                                setSelectedAge(Number(age.value));
                              }}
                            />
                            {/* <Dropdown
                              generalHelperClass={
                                props.riskHelper.generalHelperClass
                              }
                              list={props.riskHelper.configHelperClass.ageOptions()}
                              onItemClick={(age) => {
                                console.log("age:" + age);
                                setSelectedAge(age);
                              }}
                              onSelectedItem={(age) => {
                                console.log("age:" + age);
                                setSelectedAge(age);
                              }}
                              selectedItem={1}
                            /> */}
                          </div>
                          <div className="row mt-3 entity-row">
                            <div className="column">
                              <label className="sex-label dropdown-label pb-1 font-bold">
                                Risikogruppe hinzufügen:
                              </label>
                              {calculationDropDown()}
                            </div>
                          </div>
                          <div className="row entity-selection-row">
                            <div className="column">{addRangeSliders()}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Element>
              </Element>
              <Element
                name="risk-relation"
                className="element"
                id={"risk-relation-section"}
              >
                <h2>Vergleichsgrafik</h2>

                <div
                  className="relation-chart-wrapper"
                  id={"risk-relation-traffic"}
                >
                  {RelationChart(0)}
                </div>

                {/* <Element name="risk-relation-traffic" className="element">
                  <Accordion
                    title={props.configHelperClass.relationTrafficTitle()}
                    id={"risk-relation-traffic"}
                  >
                    <p>
                      {ReactHtmlParser(
                        props.configHelperClass.relationChartInfoTraffic()
                      )}
                    </p>
                    <div className="relation-chart-wrapper"
                        id={"risk-relation-traffic"}>
                      {RelationChart(0)}
                    </div>
                  </Accordion>
                </Element>
                <Element name="risk-relation-leisure" className="element">
                  <Accordion
                    id={"risk-relation-leisure-id"}
                    title={props.configHelperClass.relationLeisureTitle()}
                  >
                    <p>
                      {ReactHtmlParser(
                        props.configHelperClass.relationChartInfoLeisure()
                      )}
                    </p>
                    <div className="relation-chart-wrapper">
                      {RelationChart(1)}
                    </div>
                  </Accordion>
                </Element> */}
              </Element>
            </div>

    

            <Sidebar
              sectionsHelperClass={props.sectionsHelperClass}
              leisureSectionsHelperClass={props.leisureSectionsHelperClass}
              generalHelperClass={props.generalHelperClass}
              apiResultProp={apiResult}
              configHelperClass={props.configHelperClass}
              languageHelperClass={props.languageHelperClass}
              trafficSumProp={yourTrafficSum()}
              leisureSumProp={yourLeisureSum()}
              averageSumProp={yourRestRisikoSum()}
              onShowDetailsPressed={() => {
                setShowRiskDetails(true);
                setTimeout(function () {
                  SmoothScroll.scrollTo("risk-details-anchor");
                }, 300);
              }}
              selectedAge={selectedAge}
              selectedSex={selectedSex}
              selectedDropDownValues={selectedDropDownValues}
            />
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }

  function yourRestRisikoSum() {

  //  if(selectedAge > -1 && selectedSex > -1 ) {
  //   return Number(
  //     props.sectionsHelperClass
  //       .meinRestRiskValue(selectedAge, selectedSex)
  //       .toFixed(4)
  //   );
    

  //  } else {
  //    return 0;
  //  }
  return 0;

    
  }

  function yourTrafficSum() {
    // return (
    //   Number(trafficSum.toFixed(4)) +
    //   Number(
    //     props.sectionsHelperClass.trafficRestRiskValue(selectedAge, selectedSex)
    //   )
    // );
    return 0;
  }
  function yourLeisureSum() {
    // return Number(leisureSum.toFixed(4));
    return 0;
  }

  function getPercentageText(percentageDecimal) {
    var percentage = Number(percentageDecimal) * 100;
    return (
      (percentageDecimal > 0 ? "+ " : "- ") +
      Math.abs(percentage).toFixed(0) +
      "%"
    );
  }

  /**
   *
   * @returns Range sliders or placeholder
   */
  function addRangeSliders() {
    // empty default state
    let rangeSliders = (
      <React.Fragment>
        <Divider className="mt-3" light />
        <Alert className="mt-3" variant="outlined" severity="info">
          <AlertTitle>Info</AlertTitle>
          Bitte wählen Sie Ihr Alter, Geschlecht und Ihre Risikogruppe(n) aus!
        </Alert>
      </React.Fragment>
    );
    console.log("Sel:");
    console.log(selectedDropDownValues);
    if (selectedDropDownValues.length > 0) {
      rangeSliders = selectedDropDownValues.map((content, index) => {
        return (
          <Rangeslider
            sectionsHelperClass={props.sectionsHelperClass}
            ref={trafficRefs.current[index]}
            key={index}
            id={content.Taetigkeit}
            lineColor={content.color}
            label={content.Taetigkeit}
            hoverContent={content.hover}
            initSelected={Number(content.Regler_Presetting)}
            section={0}
            taetigkeitId={content.id}
            iconName={content.icon}
            onRelease={(value, id) => {
              props.riskHelper.entityHelper.taetigkeitForId(id).selectedValue =
                value;
              // start calculation
              calculateRiskAPI();
              // setSelectedTrafficState({
              //   id: index,
              //   value: value,
              // })
            }}
          />
        );
      });
    }
    return rangeSliders;
  }

  function calculationDropDown() {
    return (
      <Select
        placeholder={"Auswählen..."}
        options={props.riskHelper.entityHelper.taetigkeitenDropDown()}
        defaultValue={props.entityHelper.taetigkeitenDefaultDropDownArray()}
        isClearable={false}
        isMulti={true}
        noOptionsMessage={() => "Kein Ergebnisse"}
        onChange={(selectedList, selected) => {
          console.log("selected: " + JSON.stringify(selectedList));

          if (selected.action == "remove-value") {
            var value = selected.removedValue.value;
            let newArr = selectedDropDownValues.filter(function (obj) {
              return obj.id !== value.id;
            });

            setSelectedDropDownValues(newArr);
          } else if (selected.action == "select-option") {
            var value = selected.option;
            if (
              !selectedDropDownValues.some((val) => val.id == value.value.id)
            ) {
              // add taetigkeit to dropdown vals
              setSelectedDropDownValues((selectedDropDownValues) => [
                ...selectedDropDownValues,
                props.riskHelper.entityHelper.taetigkeitForId(value.value.id),
              ]);
            }
          }
        }}
      />
    );

    // return (
    //   <React.Fragment>
    //     <Dropdown
    //       // list={props.riskHelper.configHelperClass.ageOptions()}
    //       list={props.riskHelper.entityHelper.taetigkeitenDropDown()}
    //       addedIds={_addedIds()}
    //       placeholder={"Auswählen"}
    //       onItemClick={(clickedItem, value) => {
    //         // console.log("Clicked: " + clickedItem);
    //         // console.log(
    //         //   "Clicked Value: " +
    //         //     JSON.stringify(
    //         //       props.riskHelper.entityHelper.taetigkeitForId(value.value.id )
    //         //     )
    //         // );

    //         if (
    //           !selectedDropDownValues.some((val) => val.id == value.value.id)
    //         ) {
    //           // add taetigkeit to dropdown vals
    //           setSelectedDropDownValues((selectedDropDownValues) => [
    //             ...selectedDropDownValues,
    //             props.riskHelper.entityHelper.taetigkeitForId(value.value.id),
    //           ]);
    //         }
    //       }}
    //     />
    //   </React.Fragment>
    // );
  }

  function _addedIds() {
    const added = selectedDropDownValues.map((item) => {
      return item.id; // map only items to an array
    });

    return added;
  }

  function calculateRiskAPI() {
    if (selectedDropDownValues.length > 0 && selectedAge > -1 && selectedSex > -1) {

      const _taetigkeitArr = selectedDropDownValues.map((taetigkeit) => {
        return {
          Taetigkeit_ID: Number(taetigkeit.id),
          Wert: Number(taetigkeit.selectedValue),
        };
      });
      const postJSON = {
        Alter_ID: selectedAge,
        Geschlecht_ID: selectedSex,
        Taetigkeiten: _taetigkeitArr,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postJSON),
      };
      fetch("https://risiko.kfv.at/api/calc", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          console.log("Fetched Data:");
          console.log(JSON.stringify(data));
          console.log("done");

          // fix result
          // VERGLEICHS DATEN
          if (!data.vergleichs_daten.some((e) => Number(e.Bereich_ID) === 0)) {
            /* Rest */
            data.vergleichs_daten.push({
              Risiko: null,
              Bereich: "Rest",
              Bereich_ID: "0",
            });
          }

          if (!data.vergleichs_daten.some((e) => Number(e.Bereich_ID) === 1)) {
            /* Sport */
            data.vergleichs_daten.push({
              Risiko: null,
              Bereich: "Sport",
              Bereich_ID: "1",
            });
          }

          if (!data.vergleichs_daten.some((e) => Number(e.Bereich_ID) === 2)) {
            /* Haushalt */
            data.vergleichs_daten.push({
              Risiko: null,
              Bereich: "Haushalt",
              Bereich_ID: "2",
            });
          }

          if (!data.vergleichs_daten.some((e) => Number(e.Bereich_ID) === 3)) {
            /* Verkehr */
            data.vergleichs_daten.push({
              Risiko: null,
              Bereich: "Verkehr",
              Bereich_ID: "3",
            });
          }

          // RISIKO DATEN
          if (!data.risiko_daten.some((e) => Number(e.Bereich_ID) === 0)) {
            /* Rest */
            data.risiko_daten.push({
              Risiko: null,
              Bereich: "Rest",
              Bereich_ID: "0",
            });
          }

          if (!data.risiko_daten.some((e) => Number(e.Bereich_ID) === 1)) {
            /* Sport */
            data.risiko_daten.push({
              Risiko: null,
              Bereich: "Sport",
              Bereich_ID: "1",
            });
          }

          if (!data.risiko_daten.some((e) => Number(e.Bereich_ID) === 2)) {
            /* Haushalt */
            data.risiko_daten.push({
              Risiko: null,
              Bereich: "Haushalt",
              Bereich_ID: "2",
            });
          }

          if (!data.risiko_daten.some((e) => Number(e.Bereich_ID) === 3)) {
            /* Verkehr */
            data.risiko_daten.push({
              Risiko: null,
              Bereich: "Verkehr",
              Bereich_ID: "3",
            });
          }

          // TODO: Error handling
          setApiResult(data);
        });
    }
  }

  function resetTraffic() {
    // console.log(props.sectionsHelperClass.riskCalcJsonArray[0].content);
    return props.sectionsHelperClass.riskCalcJsonArray[0].content.map(
      (content, index) => {
        return (
          <Rangeslider
            sectionsHelperClass={props.sectionsHelperClass}
            ref={trafficRefs.current[index]}
            key={index}
            id={content.Taetigkeit}
            lineColor={content.color}
            label={content.Taetigkeit}
            hoverContent={content.hover}
            initSelected={Number(content.Regler_Presetting)}
            section={0}
            taetigkeitId={content.id}
            onRelease={(value) =>
              setSelectedTrafficState({
                id: index,
                value: value,
              })
            }
          />
        );
      }
    );
  }

  function resetLeisure() {
    return props.sectionsHelperClass.riskCalcJsonArray[1].content.map(
      (content, index) => {
        return (
          <Rangeslider
            sectionsHelperClass={props.sectionsHelperClass}
            ref={leisureRefs.current[index]}
            key={index}
            id={content.Taetigkeit}
            lineColor={content.color}
            hoverContent={content.hover}
            label={content.Taetigkeit}
            initSelected={Number(content.Regler_Presetting)}
            section={1}
            taetigkeitId={content.id}
            onRelease={(value) =>
              setSelectedLeisureState({
                id: index,
                value: value,
              })
            }
          />
        );
      }
    );
  }

  function RiskDetailsContent() {
    if (showRiskDetails) {
      return (
        <React.Fragment>
          <RiskDetails
            generalHelperClass={props.generalHelperClass}
            languageHelperClass={props.languageHelperClass}
            trafficSumProp={
              Number(trafficSum.toFixed(4)) +
              Number(
                props.sectionsHelperClass.trafficRestRiskValue(
                  selectedAge,
                  selectedSex
                )
              )
            }
            leisureSumProp={Number(leisureSum.toFixed(4))}
            averageSumProp={yourRestRisikoSum()}
            onRecalculate={() => {
              resetLeisureButtonPressed();
              resetTrafficButtonPressed();
              SmoothScroll.scrollTo("header");
            }}
            selectedAge={selectedAge}
            selectedSex={selectedSex}
          ></RiskDetails>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
};

RisikorechnerNeu.propTypes = {
  riskCalcJson: PropTypes.array,
  riskDetailsJson: PropTypes.array,
  sectionsHelperClass: PropTypes.object.isRequired,
  generalHelperClass: PropTypes.object.isRequired,
  leisureSectionsHelperClass: PropTypes.object.isRequired,
  configHelperClass: PropTypes.object.isRequired,
  relationChartHelperClass: PropTypes.object.isRequired,
  languageHelperClass: PropTypes.object.isRequired,
  entityHelper: PropTypes.object.isRequired,
  vergleichsHelper: PropTypes.object.isRequired,
  riskHelper: PropTypes.object.isRequired,
};

export default RisikorechnerNeu;
