import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import Risikorechner from "./Risikorechner";
import * as serviceWorker from "./serviceWorker";

import { Link } from "react-router-dom";
import { render } from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// ReactDOM.render(<App />, document.getElementById('root'));

const MainApp = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/404" component={App} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  </Router>
);

const rootNode = document.getElementById("root");
ReactDOM.render(<MainApp />, rootNode);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
