
class Datapoint {
    stackEnum = Object.freeze({
      AVERAGE: "Durchschnitt",
      YOU: "Sie"
    });
  
    /**
     *
     * @param {Number} id the ID except for "Durchschnitt it's -1"
     * @param {String} label the label
     * @param {String} stack used to compine multiple labels in a single bar
     * @param {Number} data value
     * @param {String} color hex color
     */
    constructor(id, label, stack, dataAverageAustrian, dataYou, color) {
      this.id = id;
      this.label = label; // String
      this.stack = stack; // String
      this.dataAverageAustrian = Number(dataAverageAustrian).toFixed(4); // Int
      this.dataYou = Number(dataYou).toFixed(4); // Int
      this.color = color; // String
    }
  
    toJSON() {
      return {
        id: this.id,
        label: this.label,
        stack: this.stack,
        data: [this.dataAverageAustrian, this.dataYou],
        backgroundColor: [this.color, this.color],
        hoverBackgroundColor: [this.color, this.color]
      };
    }
  
    /**
     *
     * @param {Object} json object to convert
     */
    static fromJSON(json) {
      return new Datapoint(
        json.id,
        json.label,
        json.stack,
        json.data[0],
        json.data[1],
        json.backgroundColor
      );
    }
  }

export default Datapoint;