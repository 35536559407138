import React from "react";
import "./header.less";
import PropTypes from "prop-types";
import * as GeneralHelper from "helper/GeneralHelper";
import GeneralHelperClass from "helper/GeneralHelper";
import { slide as Menu } from 'react-burger-menu'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      randomText: this.props.generalHelperClass.getRandomText(), // init value
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      return this.setState({
        randomText: this.props.generalHelperClass.getRandomText(),
      });
    }, 20000); // 20 sec
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  showSettings (event) {
    event.preventDefault();
   
  }
  render() {
    return (
      <React.Fragment>
        <header id="header" className={this.props.className}>
          <div className="wrapper">
            <div className="row flex-wrap-initial">
              {/* ------- Column 1 -------  */}
              <div className="column header-logo">
                <img
                  src={require("assets/images/logo-kfv.svg")}
                  width="150"
                  alt="KFV Logo Risikorechner"
                />
              </div>
              {/* ------- Column 2 -------  */}
              <div className="column header-text ml-4 mr-4">
                {/* {Math.floor(Math.random() * (10 - 0)) + 0} */}
                <p>
                  <span className="did-you-know-label">Wussten Sie schon: </span>{this.state.randomText}
                </p>
              </div>
              <div className="column header-hamburger">
              {this.props.menuItem}
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
Header.propTypes = {
  generalHelperClass: PropTypes.object.isRequired,
  menuItem: PropTypes.element
};

Header.defaultProps = {};

export default Header;
