class ConfigHelperClass {
  configJson = null;

  constructor(configJson) {
    this.configJson = configJson;
  }

  heroText() {
    return this.configJson.hero;
  }

  sexOptions() {
    return this.configJson.sexOptions;
  }

  ageOptions() {
    return this.configJson.ageOptions;
  }

  relationChartInfoTraffic() {
    return this.configJson.relationChartInfoTraffic;
  }

  relationChartInfoLeisure() {
    return this.configJson.relationChartInfoLeisure;
  }

  relationTrafficTitle() {
    return this.configJson.relationChartTrafficHeader;
  }

  relationLeisureTitle() {
    return this.configJson.relationChartLeisureHeader;
  }

  /**
   * @returns Array of injury objects
   */
  relationInjuries() {
    return this.configJson.relationChartInjuries;
  }
}
export default ConfigHelperClass;
