import React, { Component } from 'react';
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import ReactHtmlParser from "react-html-parser";
import * as GeneralHelper from "helper/GeneralHelper";

class HTMLTippy extends Component {
    render() {
        return (
          <React.Fragment>
            <Tippy
            className="tippy-content"
              content={ReactHtmlParser(
                this.props.content
              )}
              interactive={true}
              // interactiveDebounce={150}
            >
              {<span className={`hover-element ${this.props.showUnderline ? "underline" : ""}`}>{this.props.children}</span>}
            </Tippy>
          </React.Fragment>
        );
    }
}

HTMLTippy.propTypes = {
    content: PropTypes.string,
    className: PropTypes.string,
    showUnderline: PropTypes.bool
};

HTMLTippy.defaultProps = {
  className: "hover-element",
  showUnderline: true
};
export default HTMLTippy;