// import sectionsJson from "assets/json/risiko_calc";
// import Config from "assets/json/config";
import Datapoint from "./Datapoint";
import * as GeneralHelper from "helper/GeneralHelper";

class LeisureSectionsHelperClass {
  leisureDataset = {};

  riskCalcJsonArray = null;
  configJson = null;
  languageHelperClass = null;

  constructor(jsonArray,configJson, languageHelperClass) {
    this.riskCalcJsonArray = jsonArray;
    this.configJson = configJson;
    this.languageHelperClass = languageHelperClass;
  }
  
  leisureObject() {
    return this.riskCalcJsonArray.find(element => element.id === 1);
  }

  // returns: the multiplier which from the selected slider value
  sliderMultiplier(sliderValue) {
    return this.configJson.reglerRiskMapping[sliderValue];
  }
  
  /**
   *
   * @param selectedAge
   * { value: '0', label: '15 - 24' },
   * { value: '1', label: '25 - 64' },
   * { value: '2', label: '65 +' }
   * @param selectedSex
   * { value: 0, label: 'Weiblich' },
   * { value: 1, label: 'Männlich' }
    @returns the multiplier for the given id and age in leisure
   * e.g.: SectionsHelper.leisureRiskValue(0,1,0)
   * -> Risk for 15-24 old Man in "Fahrrad"
   */
  leisureRiskValue(selectedAge, selectedSex, forId) {
    var value = 0;

    switch (selectedAge) {
      case 0: // 15-24 Jahre
        value =
          // weiblich
          selectedSex === 0
            ? this.leisureObject().content[forId].Risiko_w_15_24
            : this.leisureObject().content[forId].Risiko_m_15_24;
        break;

      case 1: // 25-44 Jahre
        value =
          selectedSex === 0
            ? this.leisureObject().content[forId].Risiko_w_25_44
            : this.leisureObject().content[forId].Risiko_m_25_44;
        break;
      case 2: // 45-64 Jahre
        value =
          selectedSex === 0
            ? this.leisureObject().content[forId].Risiko_w_45_64
            : this.leisureObject().content[forId].Risiko_m_45_64;
        break;

      case 3: // 65+ Jahre
        value =
          selectedSex === 0
            ? this.leisureObject().content[forId].Risiko_w_65plus
            : this.leisureObject().content[forId].Risiko_m_65plus;
        break;
      default:
        console.log("THROW - leisureRiskValue - No selected Age found!");
    }
  
    return Number(value);
  }
  
  
  
  /**
   *
   * @param {Number} selectedAge
   * @param {Number} selectedSex
   *
   * @returns new chart data object
   */
  initLeisureState(selectedAge, selectedSex) {
    var newArray = {
      labels: GeneralHelper.globalBarLabel,
      datasets: [],
      barPercentage: 0.3
    };
  
    this.leisureObject().content.forEach((element, index) => {
      var sliderMulti = this.sliderMultiplier(
        element.Regler_Presetting
      );
      var multiplier = this.leisureRiskValue(selectedAge, selectedSex, element.id);
      var dataValue = Number(sliderMulti) * Number(multiplier);
  
      /*  var singleDataSet = {
          id: element.id,
          label: element.Taetigkeit,
          stack: "Sie",
          data: [dataValue.toFixed(4)],
          backgroundColor: element.color,
          hoverBackgroundColor: element.color
        }; */
  
      let singleDataSet = new Datapoint(
        element.id,
        element.Taetigkeit,
        "Sie",
        this.averageBarForLeisure()[index],
        dataValue,
        element.color
      ).toJSON();
      singleDataSet.barPercentage= 0.3;
      newArray.datasets.push(singleDataSet);
    });
  

    return newArray;
  }
  
  /**
   *
   * @param {Number} selectedSex
   * @param {Number} selectedAge
   * @param {Object} sliderValue {id: 0, value: 8}, selected leisure id with slider value. tapped slider to update
   * @param {ChartDataObject} traffBarData
   *
   *@returns new chart data object
   */
  calculateLeisureState(
    selectedSex,
    selectedAge,
    sliderValue = undefined,
    leisureBarData = undefined
  ) {
    if (leisureBarData.datasets != undefined && leisureBarData.datasets.length == 0) {
      // return leisureDataset(selectedAge, selectedSex);
      return this.initLeisureState(selectedAge, selectedSex);
    }
  
    var localLeisureDataset = {
      labels: GeneralHelper.globalBarLabel,
      datasets: []
    };
  
    if(leisureBarData.datasets != undefined) {
      var newDataset = leisureBarData.datasets.map(element => {
        if (element.id == sliderValue.id) {
          // calculate new value
          var sliderMulti = this.sliderMultiplier(sliderValue.value);
          var multiplier = this.leisureRiskValue(selectedAge, selectedSex, element.id);
    
          // var dataValue = Number(sliderMulti) * Number(multiplier);
          // element.data[1] = dataValue.toFixed(4); // [1] -> "Sie" Value
    
          var dataValue = Number(sliderMulti) * Number(multiplier);
          let newDataArray = [Number(element.data[0]), dataValue.toFixed(4)];
    
          element.data = newDataArray;
    
          return element;
        } else {
          // e.g.: Durschnitt element
          return element;
        }
      });
  
      localLeisureDataset.datasets = newDataset;
    }
    
  
    return localLeisureDataset;
  }
  
  leisureSumFromDataset(dataset) {
    if (
      dataset == undefined ||
      dataset.datasets == undefined ||
      dataset.datasets.length <= 0
    ) {
      return 0.0;
    }
  
    var sum = 0;
    dataset.datasets.forEach(el => {
      // if (el.id >= 0) {
      // filter durchschnitt
      sum += Number(el.data[1]); // "Sie" is on second position in array
      // }
    });
    return sum;
  }
  
  leisureAverageSum() {
    return this.leisureObject().content
      .map(element => element.Risiko_Mittel)
      .reduce((total, risikoMittel) => Number(total) + Number(risikoMittel))
      .toFixed(4);
  }
  
  averageBarForLeisure() {
    var datasetForAverageBar = [];
    this.leisureObject().content.forEach(element => {
      /* const temp = new Datapoint(
        -1,
        "Durchschnitt " + element.Taetigkeit,
        "Durchschnitt",
        Number(element.Risiko_Mittel),
        element.color
      ).toJSON();
      datasetForAverageBar.push(temp); */
      datasetForAverageBar.push(Number(element.Risiko_Mittel));
    });
  
    return datasetForAverageBar;
  }
  


  
}

export default LeisureSectionsHelperClass;





// export const leisureObject = sectionsJson.find(element => element.id === 1);

// // returns: the multiplier which from the selected slider value
// export function sliderMultiplier(sliderValue) {
//   return this.configJson.reglerRiskMapping[sliderValue];
// }

// /**
//  *
//  * @param selectedAge
//  * { value: '0', label: '15 - 24' },
//  * { value: '1', label: '25 - 64' },
//  * { value: '2', label: '65 +' }
//  * @param selectedSex
//  * { value: 0, label: 'Weiblich' },
//  * { value: 1, label: 'Männlich' }
//   @returns the multiplier for the given id and age in leisure
//  * e.g.: SectionsHelper.leisureRiskValue(0,1,0)
//  * -> Risk for 15-24 old Man in "Fahrrad"
//  */
// export function leisureRiskValue(selectedAge, selectedSex, forId) {
//   var value = 0;

//   switch (selectedAge) {
//     case 0:
//       value =
//         // weiblich
//         selectedSex === 0
//           ? leisureObject.content[forId].Risiko_w_15_24
//           : leisureObject.content[forId].Risiko_m_15_24;
//       break;
//     case 1:
//       value =
//         selectedSex === 0
//           ? leisureObject.content[forId].Risiko_w_25_64
//           : leisureObject.content[forId].Risiko_m_25_64;
//       break;
//     case 2:
//       value =
//         selectedSex === 0
//           ? leisureObject.content[forId].Risiko_w_65plus
//           : leisureObject.content[forId].Risiko_m_65plus;
//       break;
//     default:
//       value =
//         selectedSex === 0
//           ? leisureObject.content[forId].Risiko_w_15_24
//           : leisureObject.content[forId].Risiko_m_15_24;
//       break;
//   }

//   return Number(value);
// }

// export const leisureDataset = {};

// /**
//  *
//  * @param {Number} selectedAge
//  * @param {Number} selectedSex
//  *
//  * @returns new chart data object
//  */
// export function initLeisureState(selectedAge, selectedSex) {
//   var newArray = {
//     labels: GeneralHelper.globalBarLabel,
//     datasets: []
//   };

//   leisureObject.content.forEach((element, index) => {
//     var sliderMulti = this.sliderMultiplier(
//       element.Regler_Presetting
//     );
//     var multiplier = leisureRiskValue(selectedAge, selectedSex, element.id);
//     var dataValue = Number(sliderMulti) * Number(multiplier);

//     /*  var singleDataSet = {
//         id: element.id,
//         label: element.Taetigkeit,
//         stack: "Sie",
//         data: [dataValue.toFixed(4)],
//         backgroundColor: element.color,
//         hoverBackgroundColor: element.color
//       }; */

//     let singleDataSet = new Datapoint(
//       element.id,
//       element.Taetigkeit,
//       "Sie",
//       averageBarForLeisure()[index],
//       dataValue,
//       element.color
//     ).toJSON();
//     newArray.datasets.push(singleDataSet);
//   });

//   return newArray;
// }

// /**
//  *
//  * @param {Number} selectedSex
//  * @param {Number} selectedAge
//  * @param {Object} sliderValue {id: 0, value: 8}, selected leisure id with slider value. tapped slider to update
//  * @param {ChartDataObject} traffBarData
//  *
//  *@returns new chart data object
//  */
// export function calculateLeisureState(
//   selectedSex,
//   selectedAge,
//   sliderValue = undefined,
//   leisureBarData = undefined
// ) {
//   if (leisureBarData.datasets.length == 0) {
//     // return leisureDataset(selectedAge, selectedSex);
//     return initLeisureState(selectedAge, selectedSex);
//   }

//   var localLeisureDataset = {
//     labels: GeneralHelper.globalBarLabel,
//     datasets: []
//   };

//   var newDataset = leisureBarData.datasets.map(element => {
//     if (element.id == sliderValue.id) {
//       // calculate new value
//       var sliderMulti = this.sliderMultiplier(sliderValue.value);
//       var multiplier = leisureRiskValue(selectedAge, selectedSex, element.id);

//       // var dataValue = Number(sliderMulti) * Number(multiplier);
//       // element.data[1] = dataValue.toFixed(4); // [1] -> "Sie" Value

//       var dataValue = Number(sliderMulti) * Number(multiplier);
//       let newDataArray = [Number(element.data[0]), dataValue.toFixed(4)];

//       element.data = newDataArray;

//       return element;
//     } else {
//       // e.g.: Durschnitt element
//       return element;
//     }
//   });

//   localLeisureDataset.datasets = newDataset;
//   return localLeisureDataset;
// }

// export function leisureSumFromDataset(dataset) {
//   if (
//     dataset == undefined ||
//     dataset.datasets == undefined ||
//     dataset.datasets.length <= 0
//   ) {
//     return 0.0;
//   }

//   var sum = 0;
//   dataset.datasets.forEach(el => {
//     // if (el.id >= 0) {
//     // filter durchschnitt
//     sum += Number(el.data[1]); // "Sie" is on second position in array
//     // }
//   });
//   return sum;
// }

// export function leisureAverageSum() {
//   return leisureObject.content
//     .map(element => element.Risiko_Mittel)
//     .reduce((total, risikoMittel) => Number(total) + Number(risikoMittel))
//     .toFixed(4);
// }

// function averageBarForLeisure() {
//   var datasetForAverageBar = [];
//   leisureObject.content.forEach(element => {
//     /* const temp = new Datapoint(
//       -1,
//       "Durchschnitt " + element.Taetigkeit,
//       "Durchschnitt",
//       Number(element.Risiko_Mittel),
//       element.color
//     ).toJSON();
//     datasetForAverageBar.push(temp); */
//     datasetForAverageBar.push(Number(element.Risiko_Mittel));
//   });

//   return datasetForAverageBar;
// }
