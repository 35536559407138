import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import "./relation-chart.less";
import PropTypes from "prop-types";
import { Chart, Bubble } from "react-chartjs-2";
import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";


class RelationChartJS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legendHtml: "",
    };
  }

  componentDidMount() {
    var newLegendHtml = this.refs.chart.chartInstance.generateLegend();
    this.setState({
      legendHtml: newLegendHtml,
    });

    // Define a plugin to provide data labels
    Chart.plugins.register({
      afterDatasetsDraw: function (chartInstance, easing) {
        // To only draw at the end of animation, check for easing === 1
        var ctx = chartInstance.chart.ctx;
        chartInstance.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.getDatasetMeta(i);
          if (!meta.hidden && meta.type == "bubble") {
            meta.data.forEach(function (element, index) {
              // Draw the text in black, with the specified font
              ctx.fillStyle = "rgb(255, 255, 255)";
              var fontSize = 13;
              var fontStyle = "normal";
              var fontFamily =
                '"Roboto", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif';
              ctx.font = Chart.helpers.fontString(
                fontSize,
                fontStyle,
                fontFamily
              );
              // Just naively convert to string for now
              // <---- ADJUST TO DESIRED TEXT --->
              // Bubble text
              var dataString = dataset.data[0].id + 1;
              var bubbleText = dataset.data[0].Taetigkeit;
              // Make sure alignment settings are correct
              ctx.textAlign = "center";
              ctx.textBaseline = "middle";
              var padding = -7;
              var position = element.tooltipPosition();
              ctx.fillText(
                bubbleText.slice(0, 3),
                position.x,
                position.y - fontSize / 2 - padding
              );
            });
          }
        });
      },
    });
  }

  render() {
    var bubbleData;
    // if(this.isTraffic()) {
    //   bubbleData = {
    //     datasets: this.props.relationChartHelperClass.getDatasetForTrafficBubble(),
    //   };
    // } else {
    //   bubbleData = {
    //     datasets: this.props.relationChartHelperClass.getDatasetForLeisureBubble(),
    //   };
    // }


    bubbleData = {
      datasets: this.props.relationChartHelperClass.getDatasetForBubbleNew(
        this.props.vergleichsHelper.vergleich(),
        this.props.selectedDropDownValues
      ),
    };

    var bubbleOptions = {
      lineAtIndex: this.isTraffic() ? undefined : 0,
      scales: {
        xAxes: [
          {
            type: "linear",
            position: "bottom",
            scaleLabel: {
              display: true,
              labelString: this.props.relationChartHelperClass.getAxesLabelFor(
                0,
                this.props.type
              ),
            },
            ticks: {
              // display: false, // remove label values
              min: 0,
              max: 240, //2.8,
              stepSize: 30, //0.4,
              callback: function (value, index, values) {
                var middleIndex = Math.round((values.length - 1) / 2);
                // Min label value
                if (index === values.length - 1) {
                  return "Viel"; // Math.min.apply(this, values);
                }
                // Max label value
                else if (index === 0) {
                  return "Wenig"; //Math.max.apply(this, values);
                }
                // Middle label value
                // else if (index === middleIndex) {
                //   return "Mittel";
                // }
                else return "";
              },
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: this.props.relationChartHelperClass.getAxesLabelFor(
                1,
                this.props.type
              ),
            },
            ticks: {
              // display: false, // remove label values
              min: 0,
              max: 1.1, //this.isTraffic() ? 2.0 : 0.7,
              stepSize: 0.2, //this.isTraffic() ? 0.4 : 0.2,
              callback: function (value, index, values) {
                var middleIndex = Math.round((values.length - 1) / 2);
                // Max label value
                if (index === values.length - 1) {
                  return "Wenig"; // Math.min.apply(this, values);
                }
                // Min label value
                else if (index === 0) {
                  return "Viel"; //Math.max.apply(this, values);
                }
                // Middle label value
                // else if (index === middleIndex) {
                //   return "Mittel";
                // }
                else return "";
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            var tooltipLabel = data.datasets[tooltipItem.datasetIndex].label;
            return tooltipLabel;
          },
        },
      },
      legend: {
        display: false,
        position: "bottom",
        align: "start",
        labels: {
          usePointStyle: true,
        },
        fullWidth: false,
      },
      legendCallback: (chart) => {
        var text = [];
        text.push('<ul class="' + chart.id + '-legend chart-ul">');
        for (var i = 0; i < chart.data.datasets.length; i++) {
          var li = (
            <li className="chart-listing" onClick={this.onItemClick}>
              <span
                className="bullet"
                style={{
                  backgroundColor: chart.data.datasets[i].backgroundColor,
                }}
              >
                &nbsp;{/* {chart.data.datasets[i].data[0].id + 1} */}
              </span>
              {chart.data.datasets[i].label ? chart.data.datasets[i].label : ""}
            </li>
          );

          text.push(ReactDOMServer.renderToStaticMarkup(li));
        }
        text.push("</ul>");

        var finishedText = text.join("");
        return finishedText;
      },
    };

    var customLegendHtml = [];
    customLegendHtml.push('<h3 class="">Verletzungsschwere</h3>');
    customLegendHtml.push(
      this.props.relationChartHelperClass.prepareLegendHtmlString()
    );

    return (
      <React.Fragment>
        <div className="relation-chart-legend">
          {parse(customLegendHtml.join(""))}
        </div>
        <Bubble
          ref="chart"
          type="bubble"
          data={bubbleData}
          options={bubbleOptions}
        ></Bubble>
        <div className="relation-chart-main-legend">
          {ReactHtmlParser(this.state.legendHtml)}
        </div>
      </React.Fragment>
    );
  }

  onItemClick(event) {
    event.currentTarget.style.backgroundColor = "#ccc";
  }

  isTraffic() {
    return this.props.type === 0;
  }
}

RelationChartJS.propTypes = {
  relationChartHelperClass: PropTypes.object.isRequired,
  generalHelperClass: PropTypes.object.isRequired,
  type: PropTypes.number.isRequired, // 0 = Verkehr, 1 = Freizeit
  entityHelper: PropTypes.object.isRequired,
  riskHelper: PropTypes.object.isRequired,
  vergleichsHelper: PropTypes.object.isRequired,
  selectedDropDownValues: PropTypes.array.isRequired,
};

export default RelationChartJS;
