// import sectionsJson from "assets/json/risiko_calc";
// import Config from "assets/json/config";
import Datapoint from "./Datapoint";
import * as GeneralHelper from "helper/GeneralHelper";

class SectionsHelperClass {
  riskCalcJsonArray = null;
  configJson = null;
  languageHelperClass = null;

  constructor(jsonArray, configJson, languageHelperClass) {
    this.riskCalcJsonArray = jsonArray;
    this.configJson = configJson;
    this.languageHelperClass = languageHelperClass;
  }

  trafficObject() {
    return this.riskCalcJsonArray.find((element) => element.id === 0);
  }

  restObject() {
    if (this.riskCalcJsonArray != null) {
      return this.riskCalcJsonArray.find((element) => element.id === 2);
    }
    return null;
  }

  restMittel() {
    return this.restObject().content[0].Risiko_Mittel;
  }
  trafficRestMittel() {
    return Number(0);
    // return this.restObject().content[1].Risiko_Mittel;
  }

  // returns: the multiplier which from the selected slider value
  sliderMultiplier(sliderValue) {
    return this.configJson.reglerRiskMapping[sliderValue];
  }

  trafficSumColor() {
    return this.configJson.colors.trafficSum;
  }
  leisureSumColor() {
    return this.configJson.colors.leisureSum;
  }
  averageSumColor() {
    return this.configJson.colors.averageSum;
  }

  /**
   *
   * @param selectedAge
   * { value: '0', label: '15 - 24' },
   * { value: '1', label: '25 - 64' },
   * { value: '2', label: '65 +' }
   * @param selectedSex
   * { value: 0, label: 'Weiblich' },
   * { value: 1, label: 'Männlich' }
    @returns the multiplier for the given id and age in traffic
   * e.g.: SectionsHelper.trafficRiskValue(0,1,0)
   * -> Risk for 15-24 old Man in "Fahrrad"
   */
  trafficRiskValue(selectedAge, selectedSex, forId) {
    var value = 0;

    switch (selectedAge) {
      case 0: // 15 - 24
        value =
          // weiblich
          selectedSex === 0
            ? this.trafficObject().content[forId].Risiko_w_15_24
            : this.trafficObject().content[forId].Risiko_m_15_24;
        break;
      case 1: // 25 - 44
        value =
          selectedSex === 0
            ? this.trafficObject().content[forId].Risiko_w_25_44
            : this.trafficObject().content[forId].Risiko_m_25_44;
        break;
      case 2: // 45 - 64
        value =
          selectedSex === 0
            ? this.trafficObject().content[forId].Risiko_w_45_64
            : this.trafficObject().content[forId].Risiko_m_45_64;
        break;
      case 3: // 65 +
        value =
          selectedSex === 0
            ? this.trafficObject().content[forId].Risiko_w_65plus
            : this.trafficObject().content[forId].Risiko_m_65plus;
        break;
      default:
        throw "trafficRiskValue - No selected Age found!";
    }

    return Number(value);
  }

  trafficDataset = {};

  /**
   *
   * @param {Number} selectedAge
   * @param {Number} selectedSex
   *
   * @returns new chart data object
   */
  initTrafficState(selectedAge, selectedSex) {
    var newArray = {
      labels: GeneralHelper.globalBarLabel,
      datasets: [],
    };

    this.trafficObject().content.forEach((element, index) => {
      var sliderMulti = this.sliderMultiplier(element.Regler_Presetting);
      var multiplier = this.trafficRiskValue(
        selectedAge,
        selectedSex,
        element.id
      );
      var dataValue = Number(sliderMulti) * Number(multiplier);

      /*  var singleDataSet = {
        id: element.id,
        label: element.Taetigkeit,
        stack: "Sie",
        data: [dataValue.toFixed(4)],
        backgroundColor: element.color,
        hoverBackgroundColor: element.color
      }; */

      let singleDataSet = new Datapoint(
        element.id,
        element.Taetigkeit,
        "Sie",
        this.averageBarForTraffic()[index],
        dataValue,
        element.color
      ).toJSON();
      singleDataSet.barPercentage = 0.3;
      // singleDataSet.minBarLength =
      newArray.datasets.push(singleDataSet);
    });

    return newArray;
  }

  /**
   *
   * @param {Number} selectedSex
   * @param {Number} selectedAge
   * @param {Object} sliderValue {id: 0, value: 8}, selected traffic id with slider value. tapped slider to update
   * @param {ChartDataObject} traffBarData
   *
   *@returns new chart data object
   */
  calculateTrafficState(
    selectedSex,
    selectedAge,
    sliderValue = undefined,
    traffBarData = undefined
  ) {
    if (traffBarData.datasets != undefined && traffBarData.datasets.length == 0) {
      return this.initTrafficState(selectedAge, selectedSex);
    }

    var trafficDataset = {
      labels: GeneralHelper.globalBarLabel,
      datasets: [],
    };

    if (traffBarData.datasets != undefined) {
      var newDataset = traffBarData.datasets.map((element) => {
        if (element.id == sliderValue.id) {
          // calculate new value
          var sliderMulti = this.sliderMultiplier(sliderValue.value);
          var multiplier = this.trafficRiskValue(
            selectedAge,
            selectedSex,
            element.id
          );

          var dataValue = Number(sliderMulti) * Number(multiplier);
          let newDataArray = [Number(element.data[0]), dataValue.toFixed(4)];

          element.data = newDataArray;
          return element;
        } else {
          // e.g.: Durschnitt element
          return element;
        }
      });

      trafficDataset.datasets = newDataset;
    }
    return trafficDataset;
  }

  trafficSumFromDataset(dataset) {
    var sum = 0;
    dataset.datasets.forEach((el) => {
      // if (el.stack == "Sie") {
      // filter durchschnitt
      sum += Number(el.data[1]); // "Sie" is on second position in array
      // }
    });

    return sum;
  }

  trafficAverageSum() {
    return this.trafficObject()
      .content.map((element) => element.Risiko_Mittel)
      .reduce((total, meinRisiko) => Number(total) + Number(meinRisiko))
      .toFixed(4);
  }

  /**
   *
   * @param selectedAge
   * { value: '0', label: '15 - 24' },
   * { value: '1', label: '25 - 64' },
   * { value: '2', label: '65 +' }
   * @param selectedSex
   * { value: 0, label: 'Weiblich' },
   * { value: 1, label: 'Männlich' }
    @returns the multiplier for the given id and age in traffic
   * e.g.: SectionsHelper.trafficRiskValue(0,1,0)
   * -> Risk for 15-24 old Man in "Fahrrad"
   */
  meinRestRiskValue(selectedAge, selectedSex) {
    var value = 0;

    switch (selectedAge) {
      case 0: // 15 - 24
        value =
          // weiblich
          selectedSex === 0
            ? this.restObject().content[0].Risiko_w_15_24
            : this.restObject().content[0].Risiko_m_15_24;
        break;
      case 1: // 25 - 44
        value =
          selectedSex === 0
            ? this.restObject().content[0].Risiko_w_25_44
            : this.restObject().content[0].Risiko_m_25_44;
        break;
      case 2: // 45 - 64
        value =
          selectedSex === 0
            ? this.restObject().content[0].Risiko_w_45_64
            : this.restObject().content[0].Risiko_m_45_64;
        break;
      case 3: // 65 +
        value =
          selectedSex === 0
            ? this.restObject().content[0].Risiko_w_65plus
            : this.restObject().content[0].Risiko_m_65plus;
        break;
      default:
        throw "meinRestRiskValue - No selected Age found!";
    }

    return Number(value);
  }

  trafficRestRiskValue(selectedAge, selectedSex) {
    var value = 0;

    // switch (selectedAge) {
    //   case 0:
    //     value =
    //       // weiblich
    //       selectedSex === 0
    //         ? this.restObject().content[1].Risiko_w_15_24
    //         : this.restObject().content[1].Risiko_m_15_24;
    //     break;
    //   case 1:
    //     value =
    //       selectedSex === 0
    //         ? this.restObject().content[1].Risiko_w_25_64
    //         : this.restObject().content[1].Risiko_m_25_64;
    //     break;
    //   case 2:
    //     value =
    //       selectedSex === 0
    //         ? this.restObject().content[1].Risiko_w_65plus
    //         : this.restObject().content[1].Risiko_m_65plus;
    //     break;
    //   default:
    //     value =
    //       selectedSex === 0
    //         ? this.restObject().content[1].Risiko_w_15_24
    //         : this.restObject().content[1].Risiko_m_15_24;
    //     break;
    // }

    return Number(value);
  }

  averageBarForTraffic() {
    var datasetForAverageBar = [];
    this.trafficObject().content.forEach((element) => {
      /* const temp = new Datapoint(
        -1,
        "Durchschnitt " + element.Taetigkeit,
        "Durchschnitt",
        Number(element.Risiko_Mittel),
        element.color
      ).toJSON();
      datasetForAverageBar.push(temp); */
      datasetForAverageBar.push(Number(element.Risiko_Mittel));
    });

    return datasetForAverageBar;
  }
}

export default SectionsHelperClass;

// export const riskCalcJson = sectionsJson;

// export const trafficObject = sectionsJson.find(
//   element => element.id === 0
// );

// export const restObject = sectionsJson.find(element => element.id === 2);

// // returns: the multiplier which from the selected slider value
// export function sliderMultiplier(sliderValue) {
//   return this.configJson.reglerRiskMapping[sliderValue];
// }

// export const trafficSumColor = this.configJson.colors.trafficSum;
// export const leisureSumColor = this.configJson.colors.leisureSum;
// export const averageSumColor = this.configJson.colors.averageSum;

// /**
//  *
//  * @param selectedAge
//  * { value: '0', label: '15 - 24' },
//  * { value: '1', label: '25 - 64' },
//  * { value: '2', label: '65 +' }
//  * @param selectedSex
//  * { value: 0, label: 'Weiblich' },
//  * { value: 1, label: 'Männlich' }
//   @returns the multiplier for the given id and age in traffic
//  * e.g.: SectionsHelper.trafficRiskValue(0,1,0)
//  * -> Risk for 15-24 old Man in "Fahrrad"
//  */
// export function trafficRiskValue(selectedAge, selectedSex, forId) {
//   var value = 0;

//   switch (selectedAge) {
//     case 0:
//       value =
//         // weiblich
//         selectedSex === 0
//           ? trafficObject.content[forId].Risiko_w_15_24
//           : trafficObject.content[forId].Risiko_m_15_24;
//       break;
//     case 1:
//       value =
//         selectedSex === 0
//           ? trafficObject.content[forId].Risiko_w_25_64
//           : trafficObject.content[forId].Risiko_m_25_64;
//       break;
//     case 2:
//       value =
//         selectedSex === 0
//           ? trafficObject.content[forId].Risiko_w_65plus
//           : trafficObject.content[forId].Risiko_m_65plus;
//       break;
//     default:
//       value =
//         selectedSex === 0
//           ? trafficObject.content[forId].Risiko_w_15_24
//           : trafficObject.content[forId].Risiko_m_15_24;
//       break;
//   }

//   return Number(value);
// }

// export const trafficDataset = {};

// /**
//  *
//  * @param {Number} selectedAge
//  * @param {Number} selectedSex
//  *
//  * @returns new chart data object
//  */
// export function initTrafficState(selectedAge, selectedSex) {

//   var newArray = {
//     labels: GeneralHelper.globalBarLabel,
//     datasets: []
//   };

//   trafficObject.content.forEach((element, index) => {
//     var sliderMulti = sliderMultiplier(element.Regler_Presetting);
//     var multiplier = trafficRiskValue(selectedAge, selectedSex, element.id);
//     var dataValue = Number(sliderMulti) * Number(multiplier);

//     /*  var singleDataSet = {
//       id: element.id,
//       label: element.Taetigkeit,
//       stack: "Sie",
//       data: [dataValue.toFixed(4)],
//       backgroundColor: element.color,
//       hoverBackgroundColor: element.color
//     }; */

//     let singleDataSet = new Datapoint(
//       element.id,
//       element.Taetigkeit,
//       "Sie",
//       averageBarForTraffic()[index],
//       dataValue,
//       element.color
//     ).toJSON();
//     newArray.datasets.push(singleDataSet);
//   });

//   return newArray;
// }

// /**
//  *
//  * @param {Number} selectedSex
//  * @param {Number} selectedAge
//  * @param {Object} sliderValue {id: 0, value: 8}, selected traffic id with slider value. tapped slider to update
//  * @param {ChartDataObject} traffBarData
//  *
//  *@returns new chart data object
//  */
// export function calculateTrafficState(
//   selectedSex,
//   selectedAge,
//   sliderValue = undefined,
//   traffBarData = undefined
// ) {
//   if (traffBarData.datasets.length == 0) {
//     return initTrafficState(selectedAge, selectedSex);
//   }

//   var trafficDataset = {
//     labels: GeneralHelper.globalBarLabel,
//     datasets: []
//   };

//   var newDataset = traffBarData.datasets.map(element => {
//     if (element.id == sliderValue.id) {
//       // calculate new value
//       var sliderMulti = sliderMultiplier(sliderValue.value);
//       var multiplier = trafficRiskValue(selectedAge, selectedSex, element.id);

//       var dataValue = Number(sliderMulti) * Number(multiplier);
//       let newDataArray = [Number(element.data[0]), dataValue.toFixed(4)];

//       element.data = newDataArray;
//       return element;
//     } else {
//       // e.g.: Durschnitt element
//       return element;
//     }
//   });

//   trafficDataset.datasets = newDataset;
//   return trafficDataset;
// }

// export function trafficSumFromDataset(dataset) {
//   var sum = 0;
//   dataset.datasets.forEach(el => {
//       // if (el.stack == "Sie") {
//       // filter durchschnitt
//       sum += Number(el.data[1]); // "Sie" is on second position in array
//     // }
//   });

//   return sum;
// }

// export function trafficAverageSum() {
//   return trafficObject.content
//     .map(element => element.Risiko_Mittel)
//     .reduce((total, meinRisiko) => Number(total) + Number(meinRisiko))
//     .toFixed(4);
// }

// export const restMittel = restObject.content[0].Risiko_Mittel;
// export const trafficRestMittel = restObject.content[1].Risiko_Mittel;

// /**
//  *
//  * @param selectedAge
//  * { value: '0', label: '15 - 24' },
//  * { value: '1', label: '25 - 64' },
//  * { value: '2', label: '65 +' }
//  * @param selectedSex
//  * { value: 0, label: 'Weiblich' },
//  * { value: 1, label: 'Männlich' }
//   @returns the multiplier for the given id and age in traffic
//  * e.g.: SectionsHelper.trafficRiskValue(0,1,0)
//  * -> Risk for 15-24 old Man in "Fahrrad"
//  */
// export function meinRestRiskValue(selectedAge, selectedSex) {
//   var value = 0;

//   switch (selectedAge) {
//     case 0:
//       value =
//         // weiblich
//         selectedSex === 0
//           ? restObject.content[0].Risiko_w_15_24
//           : restObject.content[0].Risiko_m_15_24;
//       break;
//     case 1:
//       value =
//         selectedSex === 0
//           ? restObject.content[0].Risiko_w_25_64
//           : restObject.content[0].Risiko_m_25_64;
//       break;
//     case 2:
//       value =
//         selectedSex === 0
//           ? restObject.content[0].Risiko_w_65plus
//           : restObject.content[0].Risiko_m_65plus;
//       break;
//     default:
//       value =
//         selectedSex === 0
//           ? restObject.content[0].Risiko_w_15_24
//           : restObject.content[0].Risiko_m_15_24;
//       break;
//   }

//   return Number(value);
// }

// export function trafficRestRiskValue(selectedAge, selectedSex) {
//   var value = 0;

//   switch (selectedAge) {
//     case 0:
//       value =
//         // weiblich
//         selectedSex === 0
//           ? restObject.content[1].Risiko_w_15_24
//           : restObject.content[1].Risiko_m_15_24;
//       break;
//     case 1:
//       value =
//         selectedSex === 0
//           ? restObject.content[1].Risiko_w_25_64
//           : restObject.content[1].Risiko_m_25_64;
//       break;
//     case 2:
//       value =
//         selectedSex === 0
//           ? restObject.content[1].Risiko_w_65plus
//           : restObject.content[1].Risiko_m_65plus;
//       break;
//     default:
//       value =
//         selectedSex === 0
//           ? restObject.content[1].Risiko_w_15_24
//           : restObject.content[1].Risiko_m_15_24;
//       break;
//   }

//   return Number(value);
// }

// function averageBarForTraffic() {
//   var datasetForAverageBar = [];
//   trafficObject.content.forEach(element => {
//     /* const temp = new Datapoint(
//       -1,
//       "Durchschnitt " + element.Taetigkeit,
//       "Durchschnitt",
//       Number(element.Risiko_Mittel),
//       element.color
//     ).toJSON();
//     datasetForAverageBar.push(temp); */
//     datasetForAverageBar.push(Number(element.Risiko_Mittel));
//   });

//   return datasetForAverageBar;
// }
