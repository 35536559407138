import Button from "components/Button/Button";
import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";

import { HorizontalBar } from "react-chartjs-2";

import "./Sidebar.less";
// import * as SectionsHelper from "helper/sectionsHelper";
// import SectionsHelperClass from "helper/sectionsHelper";
// import * as LeisureSectionsHelper from "helper/leisureSectionsHelper";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaInfoCircle,
  FaEmptySet,
  FaUser,
  FaUserFriends,
} from "react-icons/all";

import {} from "react-icons/all";
import GeneralHelperClass from "helper/GeneralHelper";
import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";
import { Alert, AlertTitle, Card } from "@mui/material";

const Sidebar = (props) => {
  var barOptions_stacked = {
    tooltips: {
      enabled: true,
      mode: "point",
      callbacks: {
        label: GeneralHelperClass.removeNumberInTooltip,
        title: function () {},
      },
    },
    hover: {
      animationDuration: 0,
    },
    legend: {
      display: false,
      position: "bottom",
      align: "start",
      labels: {
        filter: (legendItem, chartData) => {
          return (
            chartData.datasets[legendItem.datasetIndex].stack ==
            "Durchschnittsösterreicher"
          );
        },
      },
    },
    legendCallback: (chart) => {
      var listItem = (
        <ul className={chart.id + "-legend"}>
          {chart.data.datasets.map((content, index) => {
            if (content.stack == "Durchschnittsösterreicher") {
              return (
                <li className="chart-listing" key={index}>
                  <span
                    className="bullet"
                    style={{
                      backgroundColor: content.backgroundColor,
                    }}
                  ></span>
                  {content.label ? content.label : ""}{" "}
                  <Tippy
                    content={parse(
                      props.languageHelperClass.generalHover(content.label)
                    )}
                    interactive={true}
                    placement={"bottom"}
                    zIndex={9999}
                  >
                    <span className="information-icon-sidebar">
                      <FaInfoCircle />
                    </span>
                  </Tippy>
                </li>
              );
            }
            return null;
          })}
        </ul>
      );
      return listItem; //text.join("");
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: false,
          gridLines: { display: false },
          stacked: true,
          barPercentage: 0.4,
        },
      ],
      yAxes: [
        {
          display: false,
          gridLines: { display: false },
          stacked: true,
          barPercentage: 0.8,
          categoryPercentage: 1.0,
        },
      ],
    },
  };

  const horizontalChart = useRef(null);
  const [legendHtml, setLegendHtml] = useState("");

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    var newLegendHtml = horizontalChart.current.chartInstance.generateLegend();
    setLegendHtml(newLegendHtml);
    return () => {
      // Clean up
    };
  }, []);

  const dataAverageAustrian = {
    labels: ["Ergebnis"],
    datasets: [
      {
        label: "Grundrisiko", // HACK: for forcing new line
        stack: "Durchschnittsösterreicher",
        data: [props.sectionsHelperClass.restMittel()],
        backgroundColor: props.sectionsHelperClass.averageSumColor(),
        hoverBackgroundColor: props.sectionsHelperClass.averageSumColor(),
      },
      {
        label: "Straßenverkehr", // HACK: for forcing new line
        stack: "Durchschnittsösterreicher",
        data: [
          Number(props.sectionsHelperClass.trafficAverageSum()) +
            Number(props.sectionsHelperClass.trafficRestMittel()),
        ],
        backgroundColor: props.sectionsHelperClass.trafficSumColor(),
        hoverBackgroundColor: props.sectionsHelperClass.trafficSumColor(),
      },
      {
        label: "Freizeit",
        stack: "Durchschnittsösterreicher",
        data: [props.leisureSectionsHelperClass.leisureAverageSum()],
        backgroundColor: props.sectionsHelperClass.leisureSumColor(),
        hoverBackgroundColor: props.sectionsHelperClass.leisureSumColor(),
      },
      {
        label: "Ihr Grundriskiko",
        stack: "Sie",
        data: [props.averageSumProp],
        backgroundColor: props.sectionsHelperClass.averageSumColor(),
        hoverBackgroundColor: props.sectionsHelperClass.averageSumColor(),
      },
      {
        label: "Ihr Verkehrsrisiko",
        stack: "Sie",
        data: [Number(props.trafficSumProp)],
        backgroundColor: props.sectionsHelperClass.trafficSumColor(),
        hoverBackgroundColor: props.sectionsHelperClass.trafficSumColor(),
      },
      {
        label: "Ihr Freizeitsrisiko",
        stack: "Sie",
        data: [props.leisureSumProp],
        backgroundColor: props.sectionsHelperClass.leisureSumColor(),
        hoverBackgroundColor: props.sectionsHelperClass.leisureSumColor(),
      },
    ],
  };

  const dataApiResult = buildDataObjectFromAPI();

  function buildDataObjectFromAPI() {
    /**
  {
    "vergleichs_daten": [
      {
        "Risiko": "26258",
        "Bereich_ID": "0",
        "Bereich": "Rest"
      },
      {
        "Risiko": "11793",
        "Bereich_ID": "1",
        "Bereich": "Sport"
      },
      {
        "Risiko": "6851",
        "Bereich_ID": "2",
        "Bereich": "Haushalt"
      },
      {
        "Risiko": "13979",
        "Bereich_ID": "3",
        "Bereich": "Verkehr"
      }
    ],
    "risiko_daten": [
      {
        "Risiko": "4315",
        "Bereich": "Sport",
        "Bereich_ID": "1"
      },
      {
        "Risiko": "3635",
        "Bereich": "Haushalt",
        "Bereich_ID": "2"
      },
      {
        "Risiko": "7565",
        "Bereich": "Verkehr",
        "Bereich_ID": "3"
      },
      {
        "Risiko": "17894",
        "Bereich": "Rest",
        "Bereich_ID": "0"
      }
    ]
  }
  */
    var dataAverageAustrian = {
      labels: ["Ergebnis"],
      datasets: [],
    };

    if (Object.keys(props.apiResultProp).length > 0) {
      const vergleichsDataset = props.apiResultProp.vergleichs_daten.map(
        (val) => {
          var colr = props.sectionsHelperClass.averageSumColor();
          if (Number(val.Bereich_ID) == 0) {
            colr = "#293133";
          } else if (Number(val.Bereich_ID) == 1) {
            colr = "#F75E25";
          } else if (Number(val.Bereich_ID) == 2) {
            colr = "#B32428";
          } else if (Number(val.Bereich_ID) == 3) {
            colr = "#2271B3";
          } else {
            // Rest
            colr = "#293133";
          }

          return {
            label: val.Bereich, // HACK: for forcing new line
            stack: "Durchschnittsösterreicher",
            data: [val.Risiko],
            backgroundColor: colr,
            hoverBackgroundColor: colr,
            id: val.Bereich_ID,
          };
        }
      );

      const risikoDataset = props.apiResultProp.risiko_daten.map((val) => {
        var colr = props.sectionsHelperClass.averageSumColor();
        if (Number(val.Bereich_ID) == 0) {
          colr = "#293133";
        } else if (Number(val.Bereich_ID) == 1) {
          colr = "#F75E25";
        } else if (Number(val.Bereich_ID) == 2) {
          colr = "#B32428";
        } else if (Number(val.Bereich_ID) == 3) {
          colr = "#2271B3";
        } else {
          // Rest
          colr = "#293133";
        }

        return {
          label: val.Bereich, // HACK: for forcing new line
          stack: "Sie",
          data: [val.Risiko ?? 0.0],
          backgroundColor: colr,
          hoverBackgroundColor: colr,
          id: val.Bereich_ID,
        };
      });

      var vergleichRest = vergleichsDataset.find((obj) => {
        return obj.id == "0" || obj.id == null;
      });
      var vergleichSport = vergleichsDataset.find((obj) => {
        return obj.id == "1";
      });
      var vergleichHaushalt = vergleichsDataset.find((obj) => {
        return obj.id == "2";
      });
      var vergleichVerkehr = vergleichsDataset.find((obj) => {
        return obj.id == "3";
      });

      var sieRest = risikoDataset.find((obj) => {
        return obj.id == "0" || obj.id == null;
      });
      var sieSport = risikoDataset.find((obj) => {
        return obj.id == "1";
      });
      var sieHaushalt = risikoDataset.find((obj) => {
        return obj.id == "2";
      });
      var sieVerkehr = risikoDataset.find((obj) => {
        return obj.id == "3";
      });

      dataAverageAustrian.datasets = [
        {
          label: "Grundrisiko", // HACK: for forcing new line
          stack: "Durchschnittsösterreicher",
          data: vergleichRest.data,
          backgroundColor: vergleichRest.backgroundColor,
          hoverBackgroundColor: vergleichRest.hoverBackgroundColor,
        },
        {
          label: "Straßenverkehr", // HACK: for forcing new line
          stack: "Durchschnittsösterreicher",
          data: vergleichVerkehr.data,
          backgroundColor: vergleichVerkehr.backgroundColor,
          hoverBackgroundColor: vergleichVerkehr.hoverBackgroundColor,
        },
        {
          label: "Sport",
          stack: "Durchschnittsösterreicher",
          data: vergleichSport.data,
          backgroundColor: vergleichSport.backgroundColor,
          hoverBackgroundColor: vergleichSport.hoverBackgroundColor,
        },
        {
          label: "Haushalt",
          stack: "Durchschnittsösterreicher",
          data: vergleichHaushalt.data,
          backgroundColor: vergleichHaushalt.backgroundColor,
          hoverBackgroundColor: vergleichHaushalt.hoverBackgroundColor,
        },
        {
          label: "Ihr Grundriskiko",
          stack: "Sie",
          data: sieRest.data,
          backgroundColor: sieRest.backgroundColor,
          hoverBackgroundColor: sieRest.hoverBackgroundColor,
        },
        {
          label: "Ihr Verkehrsrisiko",
          stack: "Sie",
          data: sieVerkehr.data,
          backgroundColor: sieVerkehr.backgroundColor,
          hoverBackgroundColor: sieVerkehr.hoverBackgroundColor,
        },
        {
          label: "Ihr Sport",
          stack: "Sie",
          data: sieSport.data,
          backgroundColor: sieSport.backgroundColor,
          hoverBackgroundColor: sieSport.hoverBackgroundColor,
        },
        {
          label: "Ihr Haushalt",
          stack: "Sie",
          data: sieHaushalt.data,
          backgroundColor: sieHaushalt.backgroundColor,
          hoverBackgroundColor: sieHaushalt.hoverBackgroundColor,
        },
      ];
    }

    return dataAverageAustrian;
  }

  const percentageDecimal =
    props.generalHelperClass.calculatePercentageDifferenceToAverageAustrian(
      props.averageSumProp.toFixed(4),
      Number(props.trafficSumProp.toFixed(4)),
      props.leisureSumProp.toFixed(4)
    );
  const percentage = (percentageDecimal * 100).toFixed(0);

  function tippyContent() {
    return (
      <React.Fragment>
        <span>{ReactHtmlParser(props.configHelperClass.generalInfo())}</span>
      </React.Fragment>
    );
  }

  const [isDetailsButtonDisabled, setIsDetailButtonDisabled] = useState(false);

  function getDifferencesText() {
    if (props.selectedAge > 0 && props.selectedSex > 0) {
      return parse(
        props.generalHelperClass.differencesText(
          props.selectedAge,
          props.selectedSex
        )
      )
    }
  }

  function sidebarContent() {
    var dataApiResult = buildDataObjectFromAPI();

    var boolShow = (props.selectedDropDownValues.length > 0 && props.selectedAge > 0 && props.selectedSex > 0);
    var showContent = boolShow ? "" : "hide-sidebar-content";
    var showInfo = boolShow ? "d-none" : "";

    var sideContent = (

      <Card className={`sidebar`} variant="outlined">
      {/* <div className={`sidebar general-box-shadow`}> */}
        <h2>Ergebnis</h2>
        <Alert
          className={`${showInfo}`}
          variant="outlined"
          severity="info"
        >
          <AlertTitle>Info</AlertTitle>
          Bitte wählen Sie Ihr Alter, Geschlecht und Ihre Risikogruppe(n) aus!
        </Alert>
        <div className={`result-baserisk ${showContent}`}>
          {
            getDifferencesText()
          }
        </div>

        <div className={`bar-average-austrian ${showContent}`}>
          <Tippy
            content="Durchschnitt"
            interactive={true}
            // interactiveDebounce={150}
          >
            <div className="average-austrian-icon">
              <FaUserFriends />
            </div>
          </Tippy>
          <Tippy
            content="Ich"
            interactive={true}
            // interactiveDebounce={150}
          >
            <div className="me-austrian-icon">
              <FaUser className={"average-austrian-icon"} />
            </div>
          </Tippy>
          <HorizontalBar
            height={80}
            ref={horizontalChart}
            data={dataApiResult}
            options={barOptions_stacked}
          />
        </div>

        <div className="sidebar-chart-legend">{legendHtml}</div>
        <div className={`${showContent}`}>
          Sie haben aufgrund Ihrer Angaben ein um{" "}
          <strong>
            {percentage}% {percentageDecimal > 0 ? "erhöhtes" : "vermindertes"}
          </strong>{" "}
          Risiko, sich so schwer zu verletzen, dass Sie ins Krankenhaus müssen.
        </div>
        {/* <Button
  cssId="show-details-button"
  className="mt-5"
  cssDisabled={isDetailsButtonDisabled}
  label="Details anzeigen"
  handleClick={() => {
    setIsDetailButtonDisabled(true)
    props.onShowDetailsPressed()
  }}
/> */}
      {/* </div> */}
      </Card>
    );

    return sideContent;
  }

  return sidebarContent();
  // ----- old solution ----- //
  // return (
  //   <div className="sidebar general-box-shadow">
  //     <h2>Ergebnis</h2>
  //     <div className="result-baserisk">
  //       {parse(
  //         props.generalHelperClass.differencesText(
  //           props.selectedAge,
  //           props.selectedSex
  //         )
  //       )}
  //     </div>
  //     {/* <div className="percentage-sidebar">
  //       {percentageDecimal > 0 ? "+" : ""} {percentage.toFixed(0)}%
  //     </div> */}
  //     <div className="bar-average-austrian">
  //       <Tippy
  //         content="Durchschnitt"
  //         interactive={true}
  //         // interactiveDebounce={150}
  //       >
  //         <div className="average-austrian-icon">
  //           <FaUserFriends />
  //         </div>
  //       </Tippy>
  //       <Tippy
  //         content="Ich"
  //         interactive={true}
  //         // interactiveDebounce={150}
  //       >
  //         <div className="me-austrian-icon">
  //           <FaUser className={"average-austrian-icon"} />
  //         </div>
  //       </Tippy>
  //       <HorizontalBar
  //         height={80}
  //         ref={horizontalChart}
  //         data={dataApiResult}
  //         options={barOptions_stacked}
  //       />
  //     </div>

  //     <div className="sidebar-chart-legend">{legendHtml}</div>
  //     <div>
  //       Sie haben aufgrund Ihrer Angaben ein um{" "}
  //       <strong>
  //         {percentage}% {percentageDecimal > 0 ? "erhöhtes" : "vermindertes"}
  //       </strong>{" "}
  //       Risiko, sich so schwer zu verletzen, dass Sie ins Krankenhaus müssen.
  //     </div>
  //     {/* <Button
  //       cssId="show-details-button"
  //       className="mt-5"
  //       cssDisabled={isDetailsButtonDisabled}
  //       label="Details anzeigen"
  //       handleClick={() => {
  //         setIsDetailButtonDisabled(true)
  //         props.onShowDetailsPressed()
  //       }}
  //     /> */}
  //   </div>
  // );
};

Sidebar.propTypes = {
  trafficSumProp: PropTypes.number,
  leisureSumProp: PropTypes.number,
  averageSumProp: PropTypes.number,
  apiResultProp: PropTypes.object,
  onShowDetailsPressed: PropTypes.func,
  sectionsHelperClass: PropTypes.object.isRequired,
  leisureSectionsHelperClass: PropTypes.object.isRequired,
  generalHelperClass: PropTypes.object.isRequired,
  configHelperClass: PropTypes.object.isRequired,
  languageHelperClass: PropTypes.object.isRequired,
  selectedSex: PropTypes.number,
  selectedAge: PropTypes.number,
  selectedDropDownValues: PropTypes.array
};

Sidebar.defaultProps = {
  trafficSumProp: 0,
  leisureSumProp: 0,
  averageSumProp: 0,
};

export default Sidebar;
