class LanguageHelperClass {
  languageJson = null;

  constructor(languageJson) {
    this.languageJson = languageJson;
  }
  // ##################################################################### //
  // ############################### Header ############################## //
  // ##################################################################### //

  getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  getRandomText() {
    let bannerNewsArray = this.languageJson.header.bannerNews;

    let newsText = bannerNewsArray[this.getRandomInteger(0, 10)];

    if (newsText == undefined) {
      return "";
    }

    return newsText.news;
  }

  generalHover(name) {
    let found = this.languageJson.general.generalInfoHovers.find(
      (element) => element.label.toLowerCase() === name.toLowerCase()
    );

    return found.content;
  }

  heroText() {
    return this.languageJson.hero;
  }

  sexOptions() {
    return this.languageJson.sexOptions;
  }

  ageOptions() {
    return this.languageJson.ageOptions;
  }

  infoLeisure() {
    return this.languageJson.contentCalculation.infoStrassenverkehr;
  }

  infoTraffic() {
    return this.languageJson.contentCalculation.infoFreizeit;
  }

  trafficPercentageHover() {
    return this.languageJson.traffic_percentage_hover;
  }
  leisurePercentageHover() {
    return this.languageJson.leisure_percentage_hover;
  }

  
  /**
   * @returns Array of injury objects
   */
  relationInjuries() {
    return this.languageJson.relationChartInjuries;
  }
}
export default LanguageHelperClass;
