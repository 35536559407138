// https://svg2jsx.com/
import React from "react";

function SolidAdventureIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1024"
      height="1024"
      x="0"
      y="0"
      version="1.1"
      viewBox="0 0 1024 1024"
      xmlSpace="preserve"
    >
      <path d="M979.652 314.435c-25.566-60.438-62.16-114.718-108.765-161.324-46.605-46.606-100.884-83.199-161.322-108.763C646.98 17.877 580.51 4.455 512 4.455c-68.51 0-134.979 13.422-197.562 39.893-60.44 25.564-114.718 62.157-161.324 108.763S69.914 253.995 44.35 314.435C17.877 377.02 4.455 443.49 4.455 512S17.877 646.98 44.35 709.564c25.562 60.438 62.154 114.718 108.763 161.32 46.606 46.607 100.885 83.201 161.324 108.768 62.585 26.471 129.055 39.893 197.562 39.893 68.513 0 134.98-13.422 197.565-39.893 60.438-25.566 114.717-62.16 161.322-108.768 46.606-46.604 83.2-100.884 108.765-161.319 26.471-62.585 39.893-129.058 39.893-197.565s-13.421-134.98-39.892-197.565zm-34.077 380.716c-23.7 56.026-57.63 106.353-100.851 149.57-43.219 43.224-93.544 77.152-149.573 100.854-57.999 24.532-119.62 36.97-183.151 36.97-63.528 0-125.149-12.438-183.149-36.97-56.03-23.701-106.354-57.63-149.574-100.854-43.22-43.219-77.15-93.544-100.849-149.57C53.893 637.152 41.455 575.531 41.455 512s12.438-125.152 36.973-183.152c23.696-56.03 57.629-106.354 100.849-149.574 43.22-43.22 93.544-77.15 149.574-100.849 58-24.532 119.621-36.97 183.149-36.97 63.531 0 125.152 12.439 183.151 36.97 56.029 23.699 106.353 57.629 149.573 100.849 43.221 43.22 77.152 93.544 100.852 149.574 24.531 58 36.969 119.621 36.969 183.152s-12.44 125.152-36.97 183.151zM210.469 812.803l363-239.333 239.333-363L450.53 450.53 210.469 812.803zm336.085-336.085c19.284 19.285 19.284 50.55 0 69.836-19.285 19.284-50.553 19.284-69.836 0-19.285-19.286-19.285-50.552 0-69.836s50.551-19.284 69.836 0z"></path>
    </svg>
  );
}

export default SolidAdventureIcon;

