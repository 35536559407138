import PropTypes from "prop-types";
import Button from "components/Button/Button";
import RiskDetailsSelection from "components/RiskDetails/RiskDetailsSelection/RiskDetailsSelection";
import React, { Component } from "react";
import "./RiskDetails.less";
import * as GeneralHelper from "helper/GeneralHelper";
import GeneralHelperClass from "helper/GeneralHelper";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import HoverElement from "components/HoverElement/HoverElement";

import { Element } from 'react-scroll'

class RiskDetails extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      imageUrl: undefined,
    };
  }

  imageUrl() {
    if (this.state.imageUrl != undefined) {
      var url = require("assets/images/details-background/" +
        this.state.imageUrl);
      return url;
    }
    return undefined;
  }

  render() {
    const shareUrl = "https://risiko.kfv.at";
    const title = "KFV - Risikorechner";

    return (
      <React.Fragment>
<Element name="risk-details" className="element">
        <div
          id="risk-details"
          className={`fullwidth detail ${
            this.state.imageUrl != undefined ? "background-image" : ""
          }`}
          style={{
            backgroundImage: "url(" + this.imageUrl() + ")",
          }}
        >
          <div className="risk-details wrapper column ml-4 mr-4">
            {/* {this.imageObject()} */}
            <div className="row flexbox-center-content">
              <h1 id="risk-details-anchor" className="risk-details-header">
                Typische Verletzungen beim
              </h1>
            </div>
            <div className="row">
              {/* ------- Column 1 -------  */}
              {/* <div className="double-column mr-4">
                <h2>Persönliches Risiko: {this.getPercentageString()} </h2>
                <div>
                  Sie haben aufgrund Ihrer Angaben ein um {this.percentage()}%{" "}
                  {this.percentageDecimal() > 0 ? "erhöhtes" : "vermindertes"}{" "}
                  Risiko, sich so schwer zu verletzen, dass Sie ins Krankenhaus
                  müssen.
                </div>
              </div> */}
              {/* ------- Column 2 -------  */}
              {/* <div className="column">
                <h2>
                  {" "}
                  <HoverElement
                    hoverContent={this.props.languageHelperClass.generalHover("grundrisiko")}
                  >
                    Grundrisiko
                  </HoverElement>
                </h2>
                <div>{this.getDifferencesText()}</div>
              </div> */}
            </div>
            <RiskDetailsSelection
              generalHelperClass={this.props.generalHelperClass}
              onItemSelected={(url) => {
                this.setState({
                  imageUrl: url,
                });
              }}
            ></RiskDetailsSelection>
            <div className="row">
              <div className="column flexbox-center-content mt-5 mb-5">
                {/* <Button
                  className="mb-3"
                  label="Neu berechnen"
                  handleClick={this.props.onRecalculate}
                /> */}

                <div className="row flexbox-center-content">
                  <EmailShareButton
                    url={shareUrl}
                    quote={title}
                    className="facebook-share mr-1"
                  >
                    <EmailIcon size={32} round />
                  </EmailShareButton>
                  <FacebookShareButton
                    url={shareUrl}
                    quote={title}
                    className="facebook-share mr-1"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <TwitterShareButton
                    url={shareUrl}
                    quote={title}
                    className="facebook-share mr-1"
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>

                  <LinkedinShareButton
                    url={shareUrl}
                    quote={title}
                    className="facebook-share mr-1"
                  >
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>

                  <WhatsappShareButton
                    url={shareUrl}
                    quote={title}
                    className="facebook-share mr-1"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        </Element>
      </React.Fragment>
    );
  }

  getDifferencesText() {
    return this.props.generalHelperClass.differencesText(
      this.props.selectedAge,
      this.props.selectedSex
    );
  }

  percentageDecimal() {
    return this.props.generalHelperClass.calculatePercentageDifferenceToAverageAustrian(
      this.props.averageSumProp.toFixed(4),
      Number(this.props.trafficSumProp.toFixed(4)),
      this.props.leisureSumProp.toFixed(4)
    );
  }
  percentage() {
    return (this.percentageDecimal() * 100).toFixed(0);
  }

  getPercentageString() {
    var label = "";

    if (this.percentageDecimal() < 0) {
      label += " ";
    } else if (this.percentageDecimal() == 0) {
      label += "+/- ";
    } else {
      label += "+ ";
    }

    label += this.percentage();
    label += "%";
    return label;
  }
}

RiskDetails.propTypes = {
  trafficSumProp: PropTypes.number,
  leisureSumProp: PropTypes.number,
  averageSumProp: PropTypes.number,
  onRecalculate: PropTypes.func,
  selectedSex: PropTypes.number,
  selectedAge: PropTypes.number,
  generalHelperClass: PropTypes.object.isRequired,
};

export default RiskDetails;
