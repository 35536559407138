// import * as GeneralHelper from "helper/GeneralHelper";

class VergleichsHelper {

    vergleichResponse = null;
    taetigkeitenDropDownArray = null;
    taetigkeitenArray = null;
    vergleichArray = null;
  
    constructor(vergleichResponse) {
      this.vergleichResponse = vergleichResponse;
      this._mapVergleich();
    }
    _mapVergleich() {
      let _vergleichArray = [];
      
      // convert obects to array with IDs
      var jsonArray = this.vergleichResponse.vergleich;
      for (const key in jsonArray) {
        if (jsonArray.hasOwnProperty(key)) {
          _vergleichArray.push({
            Taetigkeit_ID: Number(key),
            Taetigkeit: jsonArray[key].Taetigkeit,
            Y: parseFloat(jsonArray[key].Y.replace(',', '.')),
            X: parseFloat(jsonArray[key].X.replace(',', '.')),
            Schwere: Number(jsonArray[key].Schwere)
          });

          // _vergleichArray.push({
          //   Taetigkeit_ID: Number(key),
          //   Taetigkeit: "Laufen/Fitness/Kraftsport/Turnen",
          //   Y: 0.55,
          //   X: 0.3696,
          //   Schwere: 1
          // });

        }
      }
      
      this.vergleichArray = _vergleichArray;

    }
  
    vergleich() {
      return this.vergleichArray;
    }
    taetigkeitenDropDown() {
      return this.taetigkeitenDropDownArray;
    }
   
  
    taetigkeitForId(id) {
      return this.taetigkeitenArray.find((element) => {
              return element.id == id;
            });
    }
  
    
    geschlecht() {
    return this.entity.geschlecht;
    }
  
    geschlechtDropDown() {
  
      function translateSex(sexShort) {
        if(sexShort == "w") {
          return 'Weiblich'
        } else if (sexShort == "m") {
          return 'Männlich'
        } else {
          return 'Divers'
        }
      }
  
      const dropDownValues = this.geschlecht().map(val => ({
        value: Number(val.Geschlecht_ID),
        label: translateSex(val.Geschlecht)
      }));
  
      return dropDownValues;
    }
  
    
  
    altersgruppen() {
      return this.entity.altersgruppen;
    }
    sparten() {
      return this.entity.sparten;
    }
  }
  
  export default VergleichsHelper;
  