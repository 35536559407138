// import riskDetailsJson from "assets/json/risk_details.json";
// import Config from "assets/json/config";
// import Datapoint from "./Datapoint";
// import * as SectionsHelper from "./sectionsHelper";

export const globalBarLabel = ["Durchschnitt", "Ich"];

class GeneralHelperClass {
  riskDetailsJson = null;
  configJson = null;
  sectionHelperClass = null;
  leisureSectionHelperClass = null;
  languageHelperClass = null;

  constructor(
    riskDetailsJsonArray,
    configJson,
    sectionHelper,
    leisureSectionHelper,
    languageHelperClass
  ) {
    this.riskDetailsJson = riskDetailsJsonArray;
    this.configJson = configJson;
    this.sectionHelperClass = sectionHelper;
    this.leisureSectionHelperClass = leisureSectionHelper;
    this.languageHelperClass = languageHelperClass;
  }

  getRiskDetailDropDownOptions() {
    let detailOptions = this.riskDetailsJson;

    let sorted = detailOptions.sort((a, b) => {
      if (b.label > a.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    // let addedDefault = [{ label: "-- Auswählen --", value: -1 }].concat(sorted);
    // return addedDefault;

    return sorted;
  }

  // getRiskDetailTextObject(riskIndex, selectedObject) {
  //   let foundTextObject = this.sectionHelperClass.riskCalcJson
  //     .find((element) => {
  //       return element.sectionName == selectedObject.section;
  //     })
  //     .content.find((element) => {
  //       return element.id == selectedObject.value;
  //     });

  //   return foundTextObject.reiskDetailText;
  // }

  /**
   * Shortens a string to a speciifc amount of words
   * @param {String} str The strick to truncate
   * @param {Number} no_of_words the number of words to keep
   * @param {Number} truncation truncation suffix
   */
  truncate(str, no_of_words, truncationSuffix = "...") {
    var truncation = "";
    if (str.split(" ").length > no_of_words) {
      truncation = truncationSuffix;
    }

    return str.split(" ").splice(0, no_of_words).join(" ") + truncation;
  }

  static removeNumberInTooltip(tooltipItem, data) {
    // extract title only
    var label = data.datasets[tooltipItem.datasetIndex].label || "";
    label = label.trim();

    // if (label) {
    //   label += ": ";
    // }

    // var percentage = Number(tooltipItem.value) * 100;
    // label += `percentage ${percentage}`;

    // label += tooltipItem.value;

    // trim whitespaces at the end
    return label.trim();
  }

  calculatePercentageDifferenceToAverageAustrian(
    youBasis,
    youTraffic,
    youLeisure
  ) {
    let youSum = Number(youBasis) + Number(youTraffic) + Number(youLeisure);

    let averageAustrianTraffic =
      Number(this.sectionHelperClass.trafficAverageSum()) +
      Number(this.sectionHelperClass.trafficRestMittel());
    let averageAustrianLeisure = Number(
      this.leisureSectionHelperClass.leisureAverageSum()
    );
    let averageAustrianRest = Number(this.sectionHelperClass.restMittel());

    let averageAustrianSum =
      averageAustrianTraffic + averageAustrianLeisure + averageAustrianRest;

    var percent = youSum / averageAustrianSum - 1;
    return Number(percent.toFixed(2));
  }

  calculateTrafficPercentageToAverageAustrian(yourTraffiSum) {
    let youSum = Number(yourTraffiSum);

    let averageAustrianTraffic =
      Number(this.sectionHelperClass.trafficAverageSum()) +
      Number(this.sectionHelperClass.trafficRestMittel());

    let averageAustrianSum =
      averageAustrianTraffic;

    var percent = youSum / averageAustrianSum - 1;
    return Number(percent.toFixed(2));
  }

  calculateLeisurePercentageToAverageAustrian(yourLeisureSum) {
    let youSum = Number(yourLeisureSum);

   
    let averageAustrianLeisure = Number(
      this.leisureSectionHelperClass.leisureAverageSum()
    );
  
    let averageAustrianSum = averageAustrianLeisure;

    var percent = youSum / averageAustrianSum - 1;
    return Number(percent.toFixed(2));
  }

  differencesText(selectedAge, selectedSex) {
    var value = "";
    // let dif = this.configJson.differences;
    switch (selectedAge) {
      case 0: // 15 - 24
        value =
          // weiblich
          selectedSex === 0
            ? this.configJson.differences.W15_24
            : this.configJson.differences.M15_24;
        break;
      case 1: // 25 - 44
        value =
          selectedSex === 0
            ? this.configJson.differences.W25_44
            : this.configJson.differences.M25_44;
        break;
      case 2: // 45 - 64
        value =
          selectedSex === 0
            ? this.configJson.differences.W45_64
            : this.configJson.differences.M45_64;
        break;
      case 3: // 65 +
        value =
          selectedSex === 0
            ? this.configJson.differences.W65
            : this.configJson.differences.M65;
        break;
      default:
        console.log("TRHOW - differencesText - No selected Age found!");
    }

    return value;
  }

  getHoverTextForString(hoverLabel) {
    if (hoverLabel == undefined || hoverLabel.length <= 0) {
      return "";
    }

    return this.configJson.hovers.find((element) => {
      return element.label === hoverLabel;
    }).content;
  }

  

  getRandomText() {
    return this.languageHelperClass.getRandomText();
  }
}
export default GeneralHelperClass;
