import Accordion from "components/Collapsible/Accordion";
import Dropdown from "components/Dropdown/Dropdown";
import React, { Component } from "react";
import "./RiskDetailsSelection.less";
import * as GeneralHelper from "helper/GeneralHelper";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

class RiskDetailsSelection extends Component {
  options = [
    { value: 0, label: "Weiblich" },
    { value: 1, label: "Männlich" },
  ];

  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      selectedRisk: this.props.generalHelperClass.getRiskDetailDropDownOptions()[0],
    };

    this.props.onItemSelected(this.state.selectedRisk.backgroundImage);
  }

  onRiskSelected(selectedObject) {
    this.props.onItemSelected(selectedObject.backgroundImage);

    this.setState({
      selectedRisk: selectedObject,
    });
  }

  showRiskDetail() {
    if (this.state.selectedRisk != undefined) {
      return (
        <div className="row">
          {/* ------- Column 1 -------  */}
          <div className="double-column mr-4">
            <div className="risk-detail-seletion row pt-5">
              <div className="row">
                <div className="column">
                  <div className="pt-2">
                    {this.state.selectedRisk.riskDetailText.mainContent}
                    {this.state.selectedRisk.riskDetailText.hinweise.map((element) => {
                      return (
                        <Accordion key={element.header} title={element.header}>
                          {ReactHtmlParser(element.content)}
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ------- Column 2 -------  */}
          <div className="column">{this.getRiskImage()}</div>
        </div>

        // <React.Fragment>
        //   <div className="row">
        //     <div className="column">
        //       <div className="pt-2">
        //         {this.state.selectedRisk.mainContent}
        //         {this.state.selectedRisk.hinweise.map(element => {
        //           return (
        //             <Accordion key={element.header} title={element.header}>
        //               {ReactHtmlParser(element.content)}
        //             </Accordion>
        //           );
        //         })}
        //       </div>
        //     </div>
        //   </div>
        // </React.Fragment>
      );
    } else {
      return null;
    }
  }

  getRiskImage() {
    if (this.state.selectedRisk.riskDetailText.imageName != undefined) {
      try {
        return (
          <React.Fragment>
            <h3>Verletzungen nach Körperteil </h3>
            <img
              className="injury-bodypart-image"
              // src="http://placehold.it/400x600/0f0/000?text=2"
              src={require("assets/images/dolls/" +
                this.state.selectedRisk.riskDetailText.imageName)}
              alt="Verletzungen"
            />
            <div className="risk-detail-seletion row pt-5"></div>
          </React.Fragment>
        );
      } catch (error) {
        return <React.Fragment></React.Fragment>;
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* Aus JSON lesen */}
        {/* <h3 className="typical-injuries">Typische Verletzungen beim</h3> */}
        {/* <Dropdown
          list={this.props.generalHelperClass.getRiskDetailDropDownOptions()}
          selectedItem={0}
          onSelectedItem={(riskIndex, value) => {
            this.onRiskSelected(riskIndex, value);
            this.props.onItemSelected(value.backgroundImage);
          }}
          onItemClick={(riskIndex, value) => {
            this.onRiskSelected(riskIndex, value);
            this.props.onItemSelected(value.backgroundImage);
          }} */}
        <Select
          placeholder={"Auswählen..."}
          className="dropdown"
          options={this.props.generalHelperClass.getRiskDetailDropDownOptions()}
          defaultValue={this.props.generalHelperClass.getRiskDetailDropDownOptions()[0]}
          isClearable={false}
          isMulti={false}
          onChange={(val) => {
            // let list = this.props.generalHelperClass.getRiskDetailDropDownOptions();
            // let riskIndex = list.indexOf(val);
            // this.onRiskSelected(riskIndex, val);
            this.onRiskSelected(val);
            
            
          }}
          
        />
        {this.showRiskDetail()}
      </React.Fragment>
    );
  }
}

RiskDetailsSelection.propTypes = {
  generalHelperClass: PropTypes.object.isRequired,
  onItemSelected: PropTypes.func,
};

export default RiskDetailsSelection;
