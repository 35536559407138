import React, { Component } from "react";
import PropTypes from "prop-types";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import ReactHtmlParser from "react-html-parser";
import * as GeneralHelper from "helper/GeneralHelper";
import GeneralHelperClass from "helper/GeneralHelper";

class HoverElement extends Component {
  render() {
    var myTippy;

    if(this.props.hoverLabel != undefined) {
      myTippy = ReactHtmlParser(
        this.props.generalHelperClass.getHoverTextForString(this.props.hoverLabel)
      );
    } else {
      myTippy = ReactHtmlParser(
        this.props.hoverContent
      );
    }

    return (
      <React.Fragment>
        <Tippy
        className="hover-element-content"
          content={myTippy}
        //   onShow={(instance)=>{
        //       var header = document.getElementById("header");
        //       header.className = 'sticky no-z-index';       
        //   }}
        //   onHide={(instance)=>{
        //     var header = document.getElementById("header");
        //     header.className = 'sticky';       
        // }}
          appendTo= {document.body}
          interactive={true}
          // interactiveDebounce={150}
        >
          {
            <span
              className={`hover-element ${
                this.props.showUnderline ? "underline" : ""
              }`}
            >
              {this.props.children}
            </span>
          }
        </Tippy>
      </React.Fragment>
    );
  }
}

HoverElement.propTypes = {
  hoverLabel: PropTypes.string,
  hoverContent: PropTypes.string,
  className: PropTypes.string,
  showUnderline: PropTypes.bool,
  generalHelperClass: PropTypes.object,
};

HoverElement.defaultProps = {
  className: "hover-element",
  showUnderline: true,
};
export default HoverElement;
